import { EnterpriseRoles, SiteRoles } from '@/common/types/enums/global'
import { IUser } from '@/common/types/interfaces/global'

const userManagementMockData: IUser[] = [
  {
    id: '1',
    name: 'Misha Kliuvak',
    email: 'mishakliuvak10@gmail.com',
    lastActivity: new Date(),
    enterpriseRoles: [EnterpriseRoles.Admin],
    siteRoles: [SiteRoles.Gate, SiteRoles.Admin],
    availableSites: [
      { id: 'site1', name: 'Site 1' },
      { id: 'site2', name: 'Site 2' },
      { id: 'site3', name: 'Site 3' }
    ],
    siteRolesList: [
      { id: '1', site: 'site1', roles: [SiteRoles.Gate] },
      { id: '2', site: 'site2', roles: [SiteRoles.Gate] },
      { id: '3', site: 'site3', roles: [SiteRoles.Admin] }
    ]
  },
  {
    id: '2',
    name: 'Xavier Xicay',
    email: 'xavier@xicay.com',
    lastActivity: undefined,
    siteRoles: [SiteRoles.Gate],
    enterpriseRoles: [],
    availableSites: [{ id: 'site1', name: 'Site 1' }],
    siteRolesList: [{ id: '1', site: 'site1', roles: [SiteRoles.Gate] }]
  },
  {
    id: '4',
    name: 'Lazy Misha',
    email: 'lazy@gmail.com',
    lastActivity: new Date('2023-12-17'),
    siteRoles: [],
    availableSites: [],
    enterpriseRoles: []
  },
  {
    id: '3',
    name: 'Chris Brumett',
    email: 'chris.brumett@gmail.com',
    lastActivity: undefined,
    siteRoles: [],
    availableSites: [],
    enterpriseRoles: [EnterpriseRoles.Admin]
  }
]

export default userManagementMockData
