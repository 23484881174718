import { yupResolver } from '@hookform/resolvers/yup'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useForm } from 'react-hook-form'

import {
  Button,
  Col,
  FontWeight,
  Input,
  Row,
  Snackbar,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { useUserContext } from '@/common/contexts/UserContext'
import { useToggleOpen } from '@/common/hooks'
import { WithHeaderTemplate } from '@/common/templates'
import { ProfileSchema } from '@/features/profile/utils'
import { Color } from '@/packages/palette'

import styles from './ProfileTemplate.module.scss'

const ProfileTemplate = () => {
  const { user } = useUserContext()

  const { open, handleOpen, handleClose } = useToggleOpen()

  const { name, email } = user
  const {
    register,
    formState: { errors, isValid, isDirty }
  } = useForm({
    resolver: yupResolver(ProfileSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      firstName: name?.split?.(' ')[0],
      lastName: name?.split?.(' ')[1],
      email
    }
  })

  return (
    <WithHeaderTemplate withoutPortalDropdown title="Account">
      <Col gap={16} className={styles.profileWrapper}>
        <Text
          type={TextTypes.TEXT_MD}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Your Profile
        </Text>

        <Row gap={16} className={styles.mainInfoHolder}>
          <Input
            {...register('firstName')}
            fullWidth
            required
            label="First Name"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
          <Input
            {...register('lastName')}
            required
            fullWidth
            label="Last Name"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Row>

        <Input
          {...register('email')}
          required
          type="email"
          label="Email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <Button className="tw-self-start" type="outlined">
          Change Password
        </Button>
        <Button
          type="primary"
          className="tw-self-start"
          disabled={!isDirty || !isValid}
          onClick={handleOpen}
        >
          Save Changes
        </Button>
      </Col>

      <Snackbar
        open={open}
        onClose={handleClose}
        icon={<CheckCircleOutlineIcon />}
        message="Your changes have been saved."
      />
    </WithHeaderTemplate>
  )
}

export default ProfileTemplate
