import { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Navigation } from '@/common/components/organisms'
import {
  enterprisePortalNavigation,
  INavigationItem,
  INavigationItems,
  sitePortalNavigation
} from '@/common/components/organisms/Navigation/navigationItems'
import { useMobileNavigationContext } from '@/common/contexts/MobileNavigationContext'
import { useUserContext } from '@/common/contexts/UserContext'
import { PortalTypes, SiteRoles } from '@/common/types/enums/global'
import { getPageTitle } from '@/common/utils/helpers'
import { useNavigator } from '@/router'

import styles from './MainLayout.module.scss'

const MainLayout = () => {
  const navigate = useNavigator()
  const location = useLocation()

  const { role, portalType } = useUserContext()
  const { navigationOpened, toggleNavigation } = useMobileNavigationContext()

  const allowedLinks = useMemo(() => {
    const routes: INavigationItems<any> =
      portalType === PortalTypes.Enterprise
        ? enterprisePortalNavigation
        : sitePortalNavigation
    const allowedRoutes = routes[role as unknown as SiteRoles]

    const links: string[] = []

    allowedRoutes.forEach((route: INavigationItem) => {
      if (route.link) {
        links.push(route.link)
        return
      }

      route.items?.forEach((subRoute) => {
        links.push(subRoute.link as string)
      })
    })

    return links
  }, [portalType, role])

  const title = useMemo(
    () => getPageTitle(location.pathname),
    [location.pathname]
  )

  useEffect(() => {
    toggleNavigation(false)

    // if (!allowedLinks.includes(location.pathname)) {
    //   navigate.to(allowedLinks[0])
    // }
  }, [location.pathname, allowedLinks])

  return (
    <div className={styles.wrapper}>
      <Navigation
        opened={navigationOpened}
        toggleOpened={toggleNavigation}
        title={title}
      />

      <Outlet />
    </div>
  )
}

export default MainLayout
