const prepareIsrUrlFilters = (
  puClassFilter: string | null,
  emissionFilter: string | null
) => {
  const values: any = {}

  if (puClassFilter) {
    values.puClass = [puClassFilter]
  }

  if (emissionFilter) {
    values.emissionType = [emissionFilter]
  }

  return Object.keys(values).length ? values : undefined
}

export default prepareIsrUrlFilters
