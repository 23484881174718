import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import {
  enterprisePortalNavigation,
  sitePortalNavigation
} from '@/common/components/organisms/Navigation/navigationItems'
import {
  Brands,
  EnterpriseRoles,
  PortalTypes,
  SiteRoles
} from '@/common/types/enums/global'
import { IAvailablePortals, IUser } from '@/common/types/interfaces/global'
import { useNavigator } from '@/router'

interface UserContextValue {
  user: IUser
  portalType: PortalTypes
  role: SiteRoles | EnterpriseRoles
  brand: Brands
  availablePortals: IAvailablePortals
  selectedPortal: string
  updateBrand: (brand: Brands) => void
  updateRole: (role: SiteRoles | EnterpriseRoles) => void
  updatePortal: (id: string) => void
}

const UserContext = React.createContext<UserContextValue>(
  {} as UserContextValue
)

const UserContextProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props
  const navigate = useNavigator()

  const [values, setValues] = useState<
    Omit<UserContextValue, 'updateBrand' | 'updateRole' | 'updatePortal'>
  >({
    user: {
      id: '1',
      enterpriseRoles: [],
      siteRoles: [],
      lastActivity: undefined,
      name: 'Misha Kliuvak',
      email: 'mishakliuvak10@gmail.com'
    },
    portalType: PortalTypes.Site,
    selectedPortal: 'abilene',

    role: SiteRoles.Admin,
    brand: Brands.Terminal,
    availablePortals: {
      enterprisePortal: true,
      sitePortals: [
        { name: 'Abilene', id: 'abilene' },
        { name: 'Dallas', id: 'dallas' }
      ]
    }
  })

  const updateBrand = useCallback(
    (brand: Brands) => {
      setValues((prev) => ({ ...prev, brand }))
    },
    [setValues]
  )

  const updatePortal = useCallback(
    (id: string) => {
      const portalType =
        id === PortalTypes.Enterprise
          ? PortalTypes.Enterprise
          : PortalTypes.Site

      const role =
        portalType === PortalTypes.Site
          ? SiteRoles.Admin
          : EnterpriseRoles.Admin

      setValues((prev) => ({
        ...prev,
        portalType,
        selectedPortal: id,
        role
      }))

      if (portalType === values.portalType) return

      if (portalType === PortalTypes.Enterprise) {
        navigate.to(
          enterprisePortalNavigation[role as EnterpriseRoles][0].link as string
        )
      } else {
        navigate.to(
          (sitePortalNavigation[role as SiteRoles][0]?.link ||
            sitePortalNavigation[role as SiteRoles][0]?.items?.[0]
              ?.link) as string
        )
      }
    },
    [setValues, values.portalType]
  )

  const updateRole = useCallback(
    (role: SiteRoles | EnterpriseRoles) => {
      setValues((prev) => ({
        ...prev,
        role
      }))
    },
    [setValues]
  )

  const providerValue = useMemo(
    () => ({
      ...values,
      updateRole,
      updatePortal,
      updateBrand
    }),
    [values]
  )

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  )
}

const useUserContext = () => useContext(UserContext)

export { UserContextProvider, useUserContext }
