import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Popover } from '@mui/material'
import clsx from 'clsx'
import { FC, KeyboardEvent, useMemo, useRef, useState } from 'react'

import { Button, FontWeight, Text, TextTypes } from '@/common/components/atoms'
import { useTableContext } from '@/common/contexts/TableContext'
import { IGroupByItem } from '@/common/types/interfaces/table'
import { Color } from '@/packages/palette'

import styles from './GroupByDropdown.module.scss'

const GroupByDropdown: FC = () => {
  const {
    groupBy: value,
    onGroupByChange: onChange,
    groupByOptions: options
  } = useTableContext()

  const containerRef = useRef<any>()
  const [open, setOpen] = useState(false)

  const selectedItem: IGroupByItem | undefined = useMemo(() => {
    if (!value || !options?.length) return undefined

    return options.find((item) => item.id === value)
  }, [value, options])

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = () => {
    setOpen(() => false)
  }

  const handleOptionClick = (id: string) => {
    handleClose()

    setTimeout(() => {
      onChange(id === 'clear' ? undefined : id)
    }, 200)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleOptionClick(event.currentTarget.id)
    }
  }

  const PopoverContent = (
    <div>
      <div
        tabIndex={0}
        className={clsx(styles.groupByOption, !value && styles.hidden)}
        onClick={() => handleOptionClick('clear')}
        onKeyDown={handleKeyDown}
      >
        <Text type={TextTypes.TEXT_MD} color={Color.green500}>
          Clear Group By
        </Text>
      </div>
      {options?.map((item) => (
        <div
          id={item.id}
          key={item.id}
          tabIndex={0}
          className={clsx(
            styles.groupByOption,
            value === item.id && styles.selected
          )}
          onClick={() => handleOptionClick(item.id)}
          onKeyDown={handleKeyDown}
        >
          <Text
            type={TextTypes.TEXT_MD}
            color={Color.gray800}
            weight={
              value === item.id ? FontWeight.SEMIBOLD : FontWeight.REGULAR
            }
          >
            {item.title}
          </Text>
        </div>
      ))}
    </div>
  )

  return (
    <div ref={containerRef}>
      <Button
        onClick={handleClick}
        type="outlined"
        keepFocus={open}
        endIcon={<ArrowDropDownIcon className={clsx(open && styles.mirror)} />}
      >
        Group by {!!selectedItem && selectedItem.title}
      </Button>

      <Popover
        disablePortal
        id="group-by-popover"
        open={open}
        anchorEl={containerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: styles.groupByDropdown }}
      >
        {PopoverContent}
      </Popover>
    </div>
  )
}

export default GroupByDropdown
