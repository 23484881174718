import { useState } from 'react'

import { WithHeaderTemplate } from '@/common/templates'
import { TransactionDirectionTypes } from '@/common/types/enums/transactionDetails'
import { GateQueue } from '@/features/gate'
import { IGateQueueItem } from '@/features/gate/types'
import { checkInItems, checkOutItems } from '@/features/gate/utils'

import styles from './GateQueueTemplate.module.scss'

const GateQueueTemplate = () => {
  const [items, setItems] = useState({
    checkInItems,
    checkOutItems
  })

  const onDelete = (type: TransactionDirectionTypes, id: string) => {
    const collectionId =
      type === TransactionDirectionTypes.CheckIn
        ? 'checkInItems'
        : 'checkOutItems'

    setItems((prev) => ({
      ...prev,
      [collectionId]: prev[collectionId].filter((item) => item.id !== id)
    }))
  }

  const onSwap = (type: TransactionDirectionTypes, id: string) => {
    const isCheckIn = type === TransactionDirectionTypes.CheckIn
    const collectionId = isCheckIn ? 'checkInItems' : 'checkOutItems'

    const item = items[collectionId].find((i) => i.id === id) as IGateQueueItem

    setItems((prev) => ({
      checkOutItems: isCheckIn
        ? [item, ...prev.checkOutItems]
        : prev.checkOutItems.filter((i) => i.id !== id),

      checkInItems: isCheckIn
        ? prev.checkInItems.filter((i) => i.id !== id)
        : [item, ...prev.checkInItems]
    }))
  }

  return (
    <WithHeaderTemplate>
      <div className={styles.queues}>
        <GateQueue
          onDelete={onDelete}
          onSwap={onSwap}
          items={items.checkInItems}
          type={TransactionDirectionTypes.CheckIn}
        />
        <GateQueue
          onDelete={onDelete}
          onSwap={onSwap}
          items={items.checkOutItems}
          type={TransactionDirectionTypes.CheckOut}
        />
      </div>
    </WithHeaderTemplate>
  )
}

export default GateQueueTemplate
