import MenuIcon from '@mui/icons-material/Menu'
import { FC, KeyboardEvent, PropsWithChildren, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { FontWeight, Row, Text, TextTypes } from '@/common/components/atoms'
import { PortalsDropdownList } from '@/common/components/molecules'
import { useMobileNavigationContext } from '@/common/contexts/MobileNavigationContext'
import { getPageTitle } from '@/common/utils/helpers'
import { Color } from '@/packages/palette'

import styles from './WithHeaderTemplate.module.scss'

interface IProps {
  title?: string
  withoutPortalDropdown?: boolean
}

const WithHeaderTemplate: FC<IProps & PropsWithChildren> = (props) => {
  const { children, title, withoutPortalDropdown } = props

  const location = useLocation()
  const { toggleNavigation } = useMobileNavigationContext()

  const navTitle = useMemo(
    () => getPageTitle(location.pathname),
    [location.pathname]
  )

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      toggleNavigation()
    }
  }

  return (
    <div className={styles.content}>
      <header className={styles.header}>
        <Row items="center" gap={8}>
          <div
            className={styles.burgerIcon}
            onClick={() => toggleNavigation()}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <MenuIcon width={16} height={16} />
          </div>

          <Text
            type={TextTypes.TEXT_XL}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            {title || navTitle}
          </Text>
        </Row>

        <Row items="stretch" gap={16} className={styles.portalsSelector}>
          <Row id="header-actions" />

          {!withoutPortalDropdown && <PortalsDropdownList />}
        </Row>
      </header>

      <div className={styles.innerContent}>{children}</div>
    </div>
  )
}

export default WithHeaderTemplate
