import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { TableColumnType } from '@/common/types/enums/table'
import { ITableColumn } from '@/common/types/interfaces/table'
import {
  arrayToString,
  millisecondsToDuration,
  userFriendlyBoolean,
  userFriendlyState
} from '@/common/utils/table'

dayjs.extend(duration)

const driversColumns = (): ITableColumn[] => [
  {
    id: 'transactionTime',
    title: 'Check-In Time',
    type: TableColumnType.Date
  },
  {
    id: 'appointmentType',
    title: 'Appointment Type',
    type: TableColumnType.Text
  },
  {
    id: 'assetName',
    title: 'ID',
    type: TableColumnType.Text
  },
  {
    id: 'chassisName',
    title: 'Chassis ID',
    type: TableColumnType.Text
  },
  {
    id: 'assetLPN',
    title: 'LPN',
    type: TableColumnType.Text
  },
  {
    id: 'assetLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState
  },
  {
    id: 'assetCarrier',
    title: 'Asset Carrier',
    type: TableColumnType.Text
  },
  {
    id: 'operatingCarrier',
    title: 'Carrier',
    type: TableColumnType.Text
  },
  {
    id: 'assetType',
    title: 'Asset Type',
    type: TableColumnType.Text
  },
  {
    id: 'arrivingLoadStatus',
    title: 'Arriving Load Status',
    type: TableColumnType.Text
  },
  {
    id: 'shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text
  },
  {
    id: 'account',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString
  },
  {
    id: 'sealNumber',
    title: 'Seal #',
    type: TableColumnType.Text
  },
  {
    id: 'sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean
  },
  {
    id: 'assetDwell',
    title: 'Site Dwell',
    type: TableColumnType.Number,
    converter: millisecondsToDuration
  },
  {
    id: 'mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean
  }
]

export default driversColumns
