import { InputAdornment, TextField } from '@mui/material'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEventHandler,
  ReactNode
} from 'react'

import styles from './Input.module.scss'

interface IProps {
  icon?: ReactNode
  id?: string
  name?: string
  digitsOnly?: boolean
  uppercase?: boolean
  variant?: 'standard' | 'outlined'
  type?: 'text' | 'number' | 'email'
  value?: string | number | undefined
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
  required?: boolean
  helperText?: string
  label?: string
  error?: boolean
  readOnly?: boolean
  fullWidth?: boolean
}

const CustomInput: ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  props,
  ref
) => {
  const {
    id,
    name,
    icon,
    variant = 'standard',
    type = 'text',
    label,
    value,
    disabled,
    placeholder,
    required,
    helperText,
    error,
    uppercase,
    onChange,
    fullWidth,
    readOnly = false,
    digitsOnly = false
  } = props

  const onChangeCustom = (e: ChangeEvent<HTMLInputElement>) => {
    const eventClone = cloneDeep(e)

    if (onChange) {
      if (uppercase) {
        eventClone.target.value = eventClone.target.value?.toUpperCase()
      }

      onChange(eventClone)
    }
  }

  const onKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (evt) => {
    if (digitsOnly && ['e', 'E', '+', '-'].includes(evt.key)) {
      evt.preventDefault()
    }
  }

  return (
    <TextField
      id={id}
      name={name}
      fullWidth={fullWidth}
      type={type}
      error={error}
      variant={variant}
      value={value}
      label={label}
      onChange={onChangeCustom}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      helperText={helperText}
      classes={{ root: styles.root }}
      inputRef={ref}
      InputProps={{
        readOnly,
        onKeyDown,
        startAdornment: icon && (
          <InputAdornment classes={{ root: styles.iconStart }} position="start">
            {icon}
          </InputAdornment>
        ),
        classes: {
          adornedStart: styles.withIcon,
          error: styles.error,
          root: clsx(styles.input, variant === 'outlined' && styles.outlined),
          disabled: styles.disabled
        }
      }}
      InputLabelProps={{
        classes: {
          asterisk: styles.asterisk,
          root: styles.label,
          error: styles.error
        }
      }}
      FormHelperTextProps={{
        classes: { root: styles.helperText, error: styles.error }
      }}
    />
  )
}

export default forwardRef(CustomInput)
