import clsx from 'clsx'
import { FC } from 'react'

import {
  FontWeight,
  Text,
  TextAlign,
  TextTypes
} from '@/common/components/atoms'
import { getFirstUsernameLetter } from '@/common/utils/helpers'
import { Color } from '@/packages/palette'

import styles from './Avatar.module.scss'

interface IProps {
  size: 'sm' | 'md' | 'lg'
  name: string
  img?: string
  color?: Color
  textColor?: Color
}

const Avatar: FC<IProps> = (props) => {
  const { size, img, name, color, textColor } = props

  const type = {
    sm: TextTypes.TEXT_XS,
    md: TextTypes.TEXT_XS,
    lg: TextTypes.TEXT_MD
  }

  const weight = {
    sm: FontWeight.SEMIBOLD,
    md: FontWeight.BOLD,
    lg: FontWeight.SEMIBOLD
  }

  return (
    <div
      className={clsx(
        styles.avatar,
        styles[size],
        color && `background-${color}`,
        textColor && textColor
      )}
    >
      {img ? (
        <img src={img} alt={name} className={styles.avatarImg} />
      ) : (
        <Text
          weight={weight[size]}
          type={type[size]}
          align={TextAlign.CENTER}
          color={Color.gray0}
        >
          {getFirstUsernameLetter(name)}
        </Text>
      )}
    </div>
  )
}

export default Avatar
