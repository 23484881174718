export enum PortalTypes {
  Site = 'site-portal',
  Enterprise = 'enterprise'
}

export enum SiteRoles {
  Admin = 'siteAdmin',
  ISRManager = 'siteIsrManager',
  Gate = 'gate',
  ViewOnly = 'viewOnly'
}

export enum EnterpriseRoles {
  Admin = 'enterpriseAdmin'
}

export enum Brands {
  Terminal = 'Terminal',
  Ryder = 'Ryder',
  NFI = 'NFI'
}
