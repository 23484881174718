import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import {
  Checkbox,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Select
} from '@/common/components/atoms'
import {
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/common/types/enums/transactionDetails'
import {
  cargoAssetTypeSelectOptions,
  carriersSelectOptions,
  loadStatusSelectOptions
} from '@/common/utils/mappers'
import {
  CheckOutSchema,
  isAssetEmpty,
  isCargoAssetMinimized,
  isChassisIdVisible,
  suggestedPuLPN,
  suggesterPowerUnitNames
} from '@/features/gate/utils'

const CheckOutCargoAssetFormSection: FC = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckOutSchema>>()

  const isManually = !watch('id')

  const puType = watch('puType')
  const assetType = watch('assetType')
  const assetName = watch('assetName')
  const loadStatus = watch('loadStatus')
  const sealMatchesPW = !!watch('sealMatchesPW')
  const assetLPN = watch('assetLPN')
  const assetCarrier = watch('assetCarrier')
  const chassisName = watch('chassisName')

  const isEmpty = isAssetEmpty(loadStatus as LoadTypes)
  const isMinimized = isCargoAssetMinimized(puType as PowerUnitTypes)
  const isChassisVisible = isChassisIdVisible(
    puType as PowerUnitTypes,
    assetType as CargoAssetTypes
  )

  return (
    <Col gap={20}>
      {!isMinimized && (
        <>
          {isManually ? (
            <Select
              required
              label="Asset Type"
              value={watch('assetType')}
              error={!!errors.assetType}
              helperText={errors.assetType?.message}
              options={cargoAssetTypeSelectOptions}
              onChange={(value) => setValue('assetType', value as string)}
            />
          ) : (
            <ReadOnlyFormValue title="Asset Type" value={assetType} />
          )}

          <Row gap={16}>
            <Select
              required
              label="Asset ID"
              value={assetName}
              options={suggesterPowerUnitNames}
              error={!!errors.assetName}
              helperText={errors.assetName?.message}
              onChange={(value) => setValue('assetName', value as string)}
            />

            {isManually ? (
              <Select
                required
                label="Asset Carrier"
                value={assetCarrier}
                options={carriersSelectOptions}
                error={!!errors.assetCarrier}
                helperText={errors.assetCarrier?.message}
                onChange={(value) => setValue('assetCarrier', value as string)}
              />
            ) : (
              <ReadOnlyFormValue title="Carrier" value={assetCarrier} />
            )}
          </Row>
        </>
      )}

      <Row gap={16}>
        <Select
          required
          label="Load Status"
          value={loadStatus}
          options={loadStatusSelectOptions}
          error={!!errors.loadStatus}
          helperText={errors.loadStatus?.message}
          onChange={(value) => setValue('loadStatus', value as string)}
        />

        {!isEmpty && (
          <Input
            {...register('shipmentNumber')}
            uppercase
            fullWidth
            label="Outbound Ref #"
            error={!!errors.shipmentNumber}
            helperText={errors.shipmentNumber?.message}
          />
        )}
      </Row>

      {!isEmpty && (
        <Col items="stretch" gap={4}>
          <Input
            {...register('sealNumber')}
            uppercase
            fullWidth
            label="Seal #"
            error={!!errors.sealNumber}
            helperText={errors.sealNumber?.message}
          />

          <Checkbox
            value={sealMatchesPW}
            label="The seal matches the paperwork "
            onChange={() => setValue('sealMatchesPW', !sealMatchesPW)}
          />
        </Col>
      )}

      {isChassisVisible &&
        (isManually ? (
          <Input
            {...register('chassisName')}
            uppercase
            fullWidth
            label="Chassis ID"
            error={!!errors.chassisName}
            helperText={errors.chassisName?.message}
          />
        ) : (
          <ReadOnlyFormValue title="Chassis ID" value={chassisName} />
        ))}

      {!isMinimized && (
        <Select
          required
          label="LPN"
          value={assetLPN}
          options={suggestedPuLPN}
          error={!!errors.assetLPN}
          helperText={errors.assetLPN?.message}
          onChange={(value) => setValue('assetLPN', value as string)}
        />
      )}
    </Col>
  )
}

export default CheckOutCargoAssetFormSection
