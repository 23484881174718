import { FC, PropsWithChildren } from 'react'

import { Logo, Row, Text, TextTypes } from '@/common/components/atoms'
import { Brands } from '@/common/types/enums/global'
import { Color } from '@/packages/palette'

import styles from './Footer.module.scss'

const Footer: FC<PropsWithChildren> = (props) => {
  const { children } = props

  return (
    <footer className={styles.footer}>
      <Row gap={20} items="center" justify="between">
        <Row items="center" gap={4}>
          <Logo size={14} color="gray" brand={Brands.Terminal} />
          <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
            Powered by Terminal
          </Text>
        </Row>

        {children}
      </Row>
    </footer>
  )
}

export default Footer
