import { TableColumnType } from '@/common/types/enums/table'
import { ITableColumn } from '@/common/types/interfaces/table'

const accountsTableColumns = (): ITableColumn[] => [
  {
    id: 'name',
    title: 'Name',
    type: TableColumnType.Text,
    className: 'color-green500'
  },
  {
    id: 'createdAt',
    title: 'Date Created',
    type: TableColumnType.Date
  },
  {
    id: 'usersNumber',
    title: 'Total Users',
    type: TableColumnType.Text
  }
]

export default accountsTableColumns
