import { useCallback, useState } from 'react'

const useToggleOpen = (initialValue?: boolean) => {
  const [open, setOpen] = useState(!!initialValue)

  const handleOpen = useCallback(() => setOpen(true), [setOpen])
  const handleClose = useCallback(() => setOpen(false), [setOpen])

  return {
    open,
    handleOpen,
    handleClose
  }
}

export default useToggleOpen
