import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip } from '@/common/components/atoms'
import {
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/common/components/molecules'
import FormSection from '@/common/components/molecules/FormSection/FormSection'
import { Modal } from '@/common/components/organisms'
import { IModalWithCloseFn } from '@/common/types/interfaces/ui'
import { replaceNullToUndefined } from '@/common/utils/helpers'
import { FormAside } from '@/features/gate'
import {
  CargoAssetFormSection,
  PowerUnitAndDriverDetails
} from '@/features/yard'
import { ICargoAssetItem } from '@/features/yard/types'
import { CargoAssetDetailsSchema } from '@/features/yard/utils'

interface IProps extends IModalWithCloseFn {
  item: ICargoAssetItem
}

const CargoAssetDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { img, lane, returnVisit, mismatch, transactionTime } = item

  const formReturn = useForm({
    resolver: yupResolver(CargoAssetDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      ...replaceNullToUndefined(item),

      date: dayjs(transactionTime || new Date()),
      time: dayjs(transactionTime || new Date()),

      appointmentType: item.appointmentType
    }
  })

  const { trigger } = formReturn

  const onSubmit = () => {
    trigger()
  }

  return (
    <Modal
      title="Cargo Asset Details"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      footer={
        <Button action="submit" onClick={onSubmit} type="primary">
          Save Changes
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            <FormAside img={img} lane={lane} returnVisit={returnVisit} />
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection dateTimeReadOnly />
            </FormSection>

            <FormSection title="Cargo Asset">
              <CargoAssetFormSection />
            </FormSection>

            <FormSection title="Power Unit and Driver Details">
              <PowerUnitAndDriverDetails item={item} />
            </FormSection>
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CargoAssetDetailsModal
