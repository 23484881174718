import { faker } from '@faker-js/faker'

import {
  accountsList,
  appointmentTypesList,
  cargoAssetTypesList,
  carriersList,
  classesList,
  emissionTypeMapper,
  eventTypesList,
  fuelTypesList,
  loadStatusesList,
  transactionDirectionsList
} from '@/common/utils/mappers'
import { IGateTransaction } from '@/features/gate/types'

const generateMockData = (): IGateTransaction => {
  const puFuelType = faker.helpers.arrayElement(fuelTypesList)
  const emissionType = emissionTypeMapper[puFuelType]

  return {
    id: faker.datatype.uuid(),

    transactionTime: faker.date.recent(),
    direction: faker.helpers.arrayElement(transactionDirectionsList),
    eventType: faker.helpers.arrayElement(eventTypesList),
    appointmentType: faker.helpers.arrayElement(appointmentTypesList),

    puName: faker.random.alphaNumeric(9).toUpperCase(),
    puLPN: faker.vehicle.vrm(),
    puLPNState: faker.address.stateAbbr(),
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    mcNum: faker.random.alphaNumeric(9).toUpperCase(),
    puVIN: faker.vehicle.vin(),
    puClass: faker.helpers.arrayElement(classesList),
    puFuelType,
    emissionType,
    operatingCarrier: faker.helpers.arrayElement(carriersList),
    account: [faker.helpers.arrayElement(accountsList)],

    driverName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    driverLicenseNum: faker.random.alphaNumeric(9).toUpperCase(),
    driverPhoneNum: faker.phone.number('(###) ###-####'),

    assetName: faker.random.alphaNumeric(9).toUpperCase(),
    assetLPN: faker.random.alphaNumeric(6).toUpperCase(),
    assetLPNState: faker.address.stateAbbr(),
    assetCarrier: faker.helpers.arrayElement(carriersList),
    assetType: faker.helpers.arrayElement(cargoAssetTypesList),
    loadStatus: faker.helpers.arrayElement(loadStatusesList),
    shipmentNumber: faker.random.alphaNumeric(9).toUpperCase(),
    sealNumber: faker.random.alphaNumeric(9).toUpperCase(),
    sealMatchPW: faker.datatype.boolean(),

    source: `${faker.name.firstName()} ${faker.name.lastName()}`,
    mismatch: faker.datatype.boolean()
  }
}

const transactionsMockData: IGateTransaction[] = Array.from(
  { length: 10 },
  generateMockData
)

export default transactionsMockData
