import { useState } from 'react'

function useModal<T>(initialValue?: T) {
  const [visible, setVisible] = useState<T | undefined>(
    initialValue ?? undefined
  )

  const closeModal = () => {
    if (typeof visible !== 'boolean') {
      setVisible(undefined)
      return
    }
    setVisible(false as T)
  }

  const openModal = (id?: T) => {
    if (typeof visible === 'boolean') {
      setVisible(true as T)
      return
    }
    setVisible(id as T)
  }

  return {
    visible,
    openModal,
    closeModal
  }
}

export default useModal
