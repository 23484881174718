const replaceNullToUndefined = (obj: any): any =>
  obj
    ? Object.entries(obj).reduce(
        (result: { [key: string]: any }, [key, value]) => {
          const tempResult = result

          tempResult[key] = value || undefined

          return tempResult
        },
        {}
      )
    : undefined

export default replaceNullToUndefined
