import { cloneDeep } from 'lodash'

import { TableColumnType } from '@/common/types/enums/table'
import { ITableColumn } from '@/common/types/interfaces/table'

interface IProps {
  rows: any
  columns: ITableColumn[]
  orderBy: string
  order: 'asc' | 'desc' | undefined
  groupBy: string | undefined
}

const sortFunc = (
  rows: any,
  column: ITableColumn,
  order: 'asc' | 'desc' | undefined
) => {
  const asc = cloneDeep(rows).sort((a: any, b: any) => {
    if ([TableColumnType.Date, TableColumnType.Number].includes(column.type)) {
      const valueA = a[column.id]
      const valueB = b[column.id]

      if (!valueA && !valueB) return 0
      if (!valueA) return 1
      if (!valueB) return -1

      return valueA - valueB
    }

    const valueA =
      typeof column.converter === 'function'
        ? column.converter(a, column.id)
        : a[column?.id]?.toString()
    const valueB =
      typeof column.converter === 'function'
        ? column.converter(b, column.id)
        : b[column.id]?.toString()

    return valueA.localeCompare(valueB)
  })

  return order === 'desc' ? asc.reverse() : asc
}

const sort = ({ rows, columns, orderBy, order, groupBy }: IProps) => {
  const column = columns.find((col) => col.id === orderBy)

  if (!column) return rows

  if (groupBy) {
    if (orderBy === 'title') {
      return sortFunc(rows, column, order)
    }

    return rows.map((row: any) => ({
      ...row,
      rows: sortFunc(row.rows, column, order)
    }))
  }

  return sortFunc(rows, column, order)
}

export default sort
