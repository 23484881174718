import { Table } from '@/common/components/organisms'
import { WithHeaderTemplate } from '@/common/templates'
import { accountsTableColumns } from '@/features/super-admin-portal/utils'

const SuperAdminPortalTemplate = () => {
  const accounts = [
    { id: 1, name: 'Ryder', createdAt: new Date(), usersNumber: 120 },
    { id: 2, name: 'NFI', createdAt: new Date(), usersNumber: 11 }
  ]

  return (
    <WithHeaderTemplate title="Super Admin Portal" withoutPortalDropdown>
      <Table
        clickable
        name="accounts"
        idAccessor="id"
        rows={accounts}
        searchPlaceholder="Search Account"
        columns={accountsTableColumns}
      />
    </WithHeaderTemplate>
  )
}

export default SuperAdminPortalTemplate
