import { FC, PropsWithChildren } from 'react'

import { FontWeight, Text, TextTypes } from '@/common/components/atoms'
import { Color } from '@/packages/palette'

import styles from './FormSection.module.scss'

interface IProps extends PropsWithChildren {
  title: string
}

const FormSection: FC<IProps> = (props) => {
  const { title, children } = props

  return (
    <div className={styles.formSection}>
      <Text
        type={TextTypes.TEXT_MD}
        weight={FontWeight.SEMIBOLD}
        color={Color.gray700}
      >
        {title}
      </Text>

      {children}
    </div>
  )
}

export default FormSection
