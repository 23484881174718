import { Classes, EmissionType } from '@/common/types/enums/transactionDetails'
import { IStatsItem } from '@/common/types/interfaces/ui'
import { IISRItem } from '@/features/isr/types'

const generateIsrStats = (rows: IISRItem[]): IStatsItem[] => [
  { id: 'total', title: 'Total Visits', value: rows.length },
  {
    id: '2b-7',
    title: 'Class 2b-7 Visits',
    value: rows.filter((item) => item.puClass === Classes.Class2b7).length,
    filterKey: 'puClass',
    filterValue: Classes.Class2b7
  },
  {
    id: 'class8',
    title: 'Class 8 Visits',
    value: rows.filter((item) => item.puClass === Classes.Class8).length,
    filterKey: 'puClass',
    filterValue: Classes.Class8
  },
  {
    id: 'nearZero',
    title: 'Near Zero Emission Visits',
    value: rows.filter((item) => item.emissionType === EmissionType.NZE).length,
    filterKey: 'emissionType',
    filterValue: EmissionType.NZE
  },
  {
    id: 'zero',
    title: 'Zero Emission Visits',
    value: rows.filter((item) => item.emissionType === EmissionType.ZE).length,
    filterKey: 'emissionType',
    filterValue: EmissionType.ZE
  }
]

export default generateIsrStats
