import * as yup from 'yup'

const EditUserSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(20, 'Max length is 50')
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim()
    .max(20, 'Max length is 50')
    .required('Last Name is required'),
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email')
    .required('Email is required'),
  enterpriseEnabled: yup.boolean(),
  siteEnabled: yup.boolean(),
  enterpriseRoles: yup.array().when('enterpriseEnabled', {
    is: true,
    then: (schema) =>
      schema.min(1, 'Role is required').required('Role is required'),
    otherwise: (schema) => schema
  }),
  siteRoles: yup.array().when('siteEnabled', {
    is: true,
    then: (schema) =>
      schema.of(
        yup.object().shape({
          id: yup.string().required(),
          site: yup.string().required('Site is required'),
          roles: yup
            .array()
            .min(1, 'Role is required')
            .required('Role is required')
        })
      ),
    otherwise: (schema) => schema
  })
})

export default EditUserSchema
