import './DateInput.scss'

import { InputAdornment } from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useState
} from 'react'

import styles from '@/common/components/atoms/Input/Input.module.scss'

interface IProps {
  error?: boolean
  type?: 'date' | 'time'
  helperText?: string
  value?: Dayjs | null
  onChange: (value: Dayjs | null) => void
  placeholder?: string
  min?: Dayjs
  max?: Dayjs
  name?: string
  label?: string
  required?: boolean
  fullWidth?: boolean
  hideDayTime?: boolean
  startIcon?: ReactNode
}

const DateInput: ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  props,
  ref
) => {
  const {
    type = 'date',
    error,
    value,
    min,
    helperText,
    fullWidth,
    max,
    name,
    label,
    required,
    onChange,
    placeholder,
    hideDayTime,
    startIcon
  } = props

  const [open, setOpen] = useState(false)

  const Component = type === 'date' ? DatePicker : TimePicker

  const dateLimitProps = type === 'date' ? { minDate: min, maxDate: max } : {}
  const timePickerProps =
    type === 'time' && hideDayTime
      ? {
          ampm: false,
          minTime: dayjs().set('hour', 1).set('minutes', 0)
        }
      : {}

  return (
    <Component
      ref={ref}
      name={name}
      value={value || null}
      onChange={onChange}
      open={open}
      label={label}
      onClose={() => setOpen(false)}
      {...dateLimitProps}
      {...timePickerProps}
      slotProps={{
        textField: {
          error,
          required,
          placeholder,
          helperText,
          variant: 'standard',
          onClick: (e) => {
            e.stopPropagation()
            setOpen(() => !open)
          },
          classes: {
            root: clsx(styles.root, fullWidth ? 'tw-w-full' : 'tw-w-115')
          },
          InputProps: {
            required,
            fullWidth,
            endAdornment: null,
            startAdornment: startIcon && (
              <InputAdornment
                classes={{ root: styles.iconStart }}
                position="start"
              >
                {startIcon}
              </InputAdornment>
            ),
            classes: { root: clsx(styles.input), adornedStart: styles.withIcon }
          },
          InputLabelProps: {
            required,
            classes: {
              asterisk: styles.asterisk,
              root: styles.label,
              error: styles.error
            }
          },
          FormHelperTextProps: {
            classes: { root: styles.helperText, error: styles.error }
          }
        }
      }}
    />
  )
}

export default forwardRef(DateInput)
