import {
  AppointmentTypes,
  PowerUnitTypes
} from '@/common/types/enums/transactionDetails'

const isCargoAssetVisible = (
  appointmentType: AppointmentTypes,
  unitType: PowerUnitTypes,
  checkIn = true
) => {
  const isDropOrLive = [AppointmentTypes.DROP, AppointmentTypes.LIVE].includes(
    appointmentType
  )
  const isVisitor = appointmentType === AppointmentTypes.Visitor
  const isVehicle = unitType === PowerUnitTypes.Vehicle

  if (checkIn) return isDropOrLive && !isVehicle

  return !isVisitor && !isVehicle
}

export default isCargoAssetVisible
