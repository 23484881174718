import dayjs from 'dayjs'

import { SiteRoles } from '@/common/types/enums/global'
import { StringKeyedObject } from '@/common/types/interfaces/common'

const prepareUserManagementUrlFilters = (
  roleFilter: string | null,
  inactivityFilter: string | null
) => {
  const values: StringKeyedObject = {}

  if (roleFilter) {
    values.role = {
      [roleFilter === SiteRoles.Admin ? 'siteRoles' : 'enterpriseRoles']: [
        roleFilter
      ]
    }
  }

  if (inactivityFilter) {
    const today = dayjs()
    const endDate = today.subtract(90, 'day')

    values.lastActivity = { endDate }
  }

  return Object.keys(values) ? values : undefined
}

export default prepareUserManagementUrlFilters
