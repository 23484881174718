import { FC } from 'react'

import { Col, ReadOnlyFormValue, Row } from '@/common/components/atoms'
import { ICargoAssetItem } from '@/features/yard/types'

interface IProps {
  item: ICargoAssetItem
}

const PowerUnitAndDriverDetails: FC<IProps> = (props) => {
  const { item } = props
  const {
    puName,
    driverName,
    driverPhoneNum,
    driverLicenseNum,
    operatingCarrier
  } = item

  return (
    <Col gap={20}>
      <ReadOnlyFormValue title="ID" value={puName} />

      <ReadOnlyFormValue title="Driver Name" value={driverName} />

      <Row gap={16}>
        <ReadOnlyFormValue title="Driver License #" value={driverLicenseNum} />
        <ReadOnlyFormValue title="Driver Phone #" value={driverPhoneNum} />
      </Row>

      <ReadOnlyFormValue title="Operating Carrier" value={operatingCarrier} />
    </Col>
  )
}

export default PowerUnitAndDriverDetails
