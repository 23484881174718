import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Col, Input, Row, Select } from '@/common/components/atoms'
import { statesOptions } from '@/common/utils/data'
import {
  accountsSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions
} from '@/common/utils/mappers'
import { IsrVisitSchema } from '@/features/isr/utils'

const VisitDetailsPowerUnit = () => {
  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof IsrVisitSchema>>()

  const lpnState = watch('puLPNState')
  const puClass = watch('puClass')
  const fuelType = watch('puFuelType')
  const account = watch('account')

  return (
    <Col items="stretch" gap={20}>
      <Row gap={16}>
        <Input
          {...register('puName')}
          uppercase
          fullWidth
          required
          label="ID"
          error={!!errors.puName}
          helperText={errors.puName?.message}
        />
        <Input
          {...register('usDOT')}
          fullWidth
          digitsOnly
          required
          type="number"
          label="USDOT"
          error={!!errors.usDOT}
          helperText={errors.usDOT?.message}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('puLPN')}
          uppercase
          fullWidth
          required
          label="LPN"
          error={!!errors.puLPN}
          helperText={errors.puLPN?.message}
        />

        <Select
          required
          label="LPN State"
          value={lpnState}
          options={statesOptions}
          error={!!errors.puLPNState}
          helperText={errors.puLPNState?.message}
          onChange={(value) => setValue('puLPNState', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('puVIN')}
          uppercase
          fullWidth
          required
          label="VIN"
          error={!!errors.puVIN}
          helperText={errors.puVIN?.message}
        />

        <Select
          required
          label="Class"
          value={puClass}
          options={classListSelectOptions}
          error={!!errors.puClass}
          helperText={errors.puClass?.message}
          onChange={(value) => setValue('puClass', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Select
          required
          label="Fuel Type"
          value={fuelType}
          options={fuelTypeSelectOptions}
          error={!!errors.puFuelType}
          helperText={errors.puFuelType?.message}
          onChange={(value) => setValue('puFuelType', value as string)}
        />

        <Select
          multiple
          required
          label="Account"
          value={account}
          options={accountsSelectOptions}
          error={!!errors.account}
          helperText={errors.account?.message}
          onChange={(value) => setValue('account', value as string[])}
        />
      </Row>
    </Col>
  )
}

export default VisitDetailsPowerUnit
