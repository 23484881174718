import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Checkbox, Col, Input, Row, Select } from '@/common/components/atoms'
import {
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/common/types/enums/transactionDetails'
import { statesOptions } from '@/common/utils/data'
import {
  cargoAssetTypeSelectOptions,
  carriersSelectOptions,
  loadStatusSelectOptions
} from '@/common/utils/mappers'
import {
  CheckInSchema,
  isAssetEmpty,
  isCargoAssetMinimized,
  isChassisIdVisible
} from '@/features/gate/utils'

const CheckInCargoAssetFormSection = () => {
  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckInSchema>>()

  const puType = watch('puType') as PowerUnitTypes
  const assetType = watch('assetType') as CargoAssetTypes
  const loadStatus = watch('loadStatus') as LoadTypes

  const carrier = watch('assetCarrier')
  const sealMatches = !!watch('sealMatchesPW')

  const isMinimized = isCargoAssetMinimized(puType)
  const isChassisVisible = isChassisIdVisible(puType, assetType)
  const isEmpty = isAssetEmpty(loadStatus)

  return (
    <Col gap={20}>
      {!isMinimized && (
        <>
          <Select
            required
            label="Asset Type"
            value={watch('assetType')}
            error={!!errors.assetType}
            helperText={errors.assetType?.message}
            options={cargoAssetTypeSelectOptions}
            onChange={(value) => setValue('assetType', value as string)}
          />

          <Row gap={16}>
            <Input
              {...register('assetName')}
              required
              uppercase
              fullWidth
              label="Asset ID"
              error={!!errors.assetName}
              helperText={errors.assetName?.message}
            />

            <Select
              required
              label="Asset Carrier"
              value={carrier}
              options={carriersSelectOptions}
              error={!!errors.assetCarrier}
              helperText={errors.assetCarrier?.message}
              onChange={(value) => setValue('assetCarrier', value as string)}
            />
          </Row>
        </>
      )}

      <Row gap={16}>
        <Select
          label="Load Status"
          value={loadStatus}
          options={loadStatusSelectOptions}
          error={!!errors.loadStatus}
          helperText={errors.loadStatus?.message}
          onChange={(value) => setValue('loadStatus', value as string)}
        />

        {!isEmpty && (
          <Input
            {...register('shipmentNumber')}
            uppercase
            fullWidth
            label="Inbound Ref #"
            error={!!errors.shipmentNumber}
            helperText={errors.shipmentNumber?.message}
          />
        )}
      </Row>

      {!isEmpty && (
        <Col items="stretch" gap={4}>
          <Input
            {...register('sealNumber')}
            uppercase
            fullWidth
            label="Seal #"
            error={!!errors.sealNumber}
            helperText={errors.sealNumber?.message}
          />

          <Checkbox
            value={sealMatches}
            label="The seal matches the paperwork "
            onChange={() => setValue('sealMatchesPW', !sealMatches)}
          />
        </Col>
      )}

      {isChassisVisible && (
        <Input
          {...register('chassisName')}
          uppercase
          fullWidth
          label="Chassis ID"
          error={!!errors.chassisName}
          helperText={errors.chassisName?.message}
        />
      )}

      {!isMinimized && (
        <Row gap={16}>
          <Input
            {...register('assetLPN')}
            uppercase
            fullWidth
            label="LPN"
            error={!!errors.assetLPN}
            helperText={errors.assetLPN?.message}
          />

          <Select
            label="LPN State/Region"
            value={watch('assetLPNState')}
            options={statesOptions}
            error={!!errors.assetLPNState}
            helperText={errors.assetLPNState?.message}
            onChange={(value) => setValue('assetLPNState', value as string)}
          />
        </Row>
      )}
    </Col>
  )
}

export default CheckInCargoAssetFormSection
