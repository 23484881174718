import dayjs from 'dayjs'

const prepareDriversUrlFilters = (
  appointmentTypeFilter: string | null,
  mismatch: string | null,
  dwell: string | null
) => {
  const values: any = {}

  if (appointmentTypeFilter) {
    values.appointmentType = [appointmentTypeFilter]
  }

  if (mismatch) {
    values.mismatch = [mismatch]
  }

  if (dwell) {
    values.puDwell = dayjs().startOf('day').add(Number(dwell), 'millisecond')
  }

  return Object.keys(values).length ? values : undefined
}

export default prepareDriversUrlFilters
