import CloseIcon from '@mui/icons-material/Close'
import { FC, MouseEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Row, Text, TextTypes } from '@/common/components/atoms'
import { TableFilterType } from '@/common/types/enums/table'
import { IFilterItem, IFilterValues } from '@/common/types/interfaces/table'
import { Color } from '@/packages/palette'

import styles from './FilterValues.module.scss'

interface IProps {
  values: IFilterValues
  filters: IFilterItem[]
  onChange: (filterId: string, value: any) => void
  toggleSpecificFilter: (
    e: MouseEvent<HTMLSpanElement>,
    filter: IFilterItem
  ) => void
}
const FilterValues: FC<IProps> = (props) => {
  const { values, filters, onChange, toggleSpecificFilter } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const renderFilterValue = (key: string, value: string | string[] | any) => {
    const filter = filters.find((item) => item.id === key)

    if (!filter) return null

    let valueTitles = ''

    switch (filter.type) {
      case TableFilterType.List: {
        valueTitles = filter.options
          .filter((option) => value.includes(option.id))
          .map((item) => item.title)
          .join(', ')
        break
      }

      case TableFilterType.Time: {
        if (!value) {
          valueTitles = '0h'
          break
        }

        const hours = value.hour()
        const minutes = value.minute()

        valueTitles = `over ${minutes ? `${hours}h ${minutes}m` : `${hours}h`}`
        break
      }

      case TableFilterType.GroupedList: {
        const groupedValues: string[] = []

        Object.keys(value).forEach((filterKey) => {
          const group = filter.options.find((item) => item.id === filterKey)

          if (!group) return

          groupedValues.push(
            `${group.title} (${group.options
              .filter((option) => value[filterKey].includes(option.id))
              .map((item) => item.title)
              .join(', ')})`
          )
        })

        valueTitles = groupedValues.join(', ')
        break
      }

      case TableFilterType.DateRange: {
        if (typeof value === 'string') {
          valueTitles = filter.extraOptions?.find(
            (option) => option.id === value
          )?.title as string

          break
        }

        valueTitles += `${
          value?.startDate ? value?.startDate?.format('MM/DD/YYYY') : 'N/A'
        } - ${value?.endDate ? value?.endDate?.format('MM/DD/YYYY') : 'N/A'}`

        break
      }

      default: {
        break
      }
    }

    return (
      <>
        {filter.name} is{' '}
        <span onClick={(e) => toggleSpecificFilter(e, filter)}>
          {valueTitles}
        </span>
      </>
    )
  }

  const clearFilter = (e: MouseEvent<HTMLDivElement>, key: string) => {
    e.stopPropagation()
    onChange(key, undefined)

    searchParams.delete(key)
    setSearchParams(searchParams)
  }

  return (
    <Row items="center" gap={8}>
      {Object.entries(values).map(
        ([key, value]) =>
          !!value && (
            <Row items="center" gap={6} key={key} className={styles.filterItem}>
              <Text
                type={TextTypes.TEXT_XS}
                color={Color.gray700}
                className={styles.valueHolder}
              >
                {renderFilterValue(key, value)}
              </Text>

              <div
                className={styles.closeIcon}
                tabIndex={0}
                onClick={(e) => clearFilter(e, key)}
              >
                <CloseIcon />
              </div>
            </Row>
          )
      )}
    </Row>
  )
}

export default FilterValues
