import { TableFilterType } from '@/common/types/enums/table'
import { IFilterItem } from '@/common/types/interfaces/table'
import {
  classesList,
  emissionNameMapper,
  emissionsList,
  fuelTypesList
} from '@/common/utils/mappers'

const isrFilters: IFilterItem[] = [
  {
    id: 'lastActivity',
    name: 'Date Range',
    type: TableFilterType.DateRange,
    fieldsToValidate: ['checkInTime', 'checkOutTime']
  },
  {
    id: 'puClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    options: classesList.map((classItem) => ({
      id: classItem,
      title: classItem
    }))
  },
  {
    id: 'puFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    options: fuelTypesList.map((fuel) => ({
      id: fuel,
      title: fuel
    }))
  },
  {
    id: 'emissionType',
    name: 'Fuel Emission Type',
    type: TableFilterType.List,
    placeholder: 'Search Emission Type',
    options: emissionsList.map((emission) => ({
      id: emission,
      title: emissionNameMapper[emission]
    }))
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    options: [
      { id: 'yes', title: 'Yes' },
      { id: 'no', title: 'No' }
    ],
    valuesMapper: {
      yes: true,
      no: false
    }
  }
]

export default isrFilters
