import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import {
  Col,
  FontWeight,
  Input,
  Radio,
  Row,
  Select,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { statesOptions } from '@/common/utils/data'
import { IsrVisitSchema } from '@/features/isr/utils'
import { Color } from '@/packages/palette'

interface IProps {
  type: 'checkIn' | 'checkOut'
}

const VisitDetailsCargoAsset: FC<IProps> = (props) => {
  const { type } = props

  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof IsrVisitSchema>>()

  const title = type === 'checkIn' ? 'Check-In' : 'Check-Out'

  const lpnState = watch(`${type}AssetLPNState`)
  const matches = !!watch(`${type}SealMatchPW`)

  const toggleMatchesValue = (value: boolean) => {
    setValue(`${type}SealMatchPW`, value)
  }

  return (
    <Col items="stretch" gap={20}>
      <Text
        type={TextTypes.TEXT_SM}
        weight={FontWeight.SEMIBOLD}
        color={Color.gray700}
      >
        {title}
      </Text>

      <Row gap={16}>
        <Input
          {...register(`${type}AssetName`)}
          uppercase
          required
          fullWidth
          label="ID"
          error={!!errors[`${type}AssetName`]}
          helperText={errors[`${type}AssetName`]?.message}
        />
        <Input
          {...register(`${type}ShipmentNumber`)}
          required
          uppercase
          fullWidth
          label="Shipment #"
          error={!!errors[`${type}ShipmentNumber`]}
          helperText={errors[`${type}ShipmentNumber`]?.message}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register(`${type}AssetLPN`)}
          required
          uppercase
          fullWidth
          label="LPN"
          error={!!errors[`${type}AssetLPN`]}
          helperText={errors[`${type}AssetLPN`]?.message}
        />

        <Select
          required
          label="LPN State"
          value={lpnState}
          options={statesOptions}
          error={!!errors[`${type}AssetLPNState`]}
          helperText={errors[`${type}AssetLPNState`]?.message}
          onChange={(value) =>
            setValue(`${type}AssetLPNState`, value as string)
          }
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register(`${type}SealNumber`)}
          fullWidth
          required
          label="Seal Number"
          error={!!errors[`${type}SealNumber`]}
          helperText={errors[`${type}SealNumber`]?.message}
        />

        <Col gap={2} className="tw-w-full">
          <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
            Seal Matches PW
          </Text>

          <Row items="center" gap={6}>
            <Radio
              label="Yes"
              checked={matches}
              onChange={() => toggleMatchesValue(true)}
            />
            <Radio
              label="No"
              checked={!matches}
              onChange={() => toggleMatchesValue(false)}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

export default VisitDetailsCargoAsset
