import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import clsx from 'clsx'
import {
  FC,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  FontWeight,
  Row,
  Text,
  TextTypes,
  Tooltip
} from '@/common/components/atoms'
import { CollapsedNavigationPopover } from '@/common/components/molecules'
import { INavigationItem } from '@/common/components/organisms/Navigation/navigationItems'
import { Color } from '@/packages/palette'

import styles from './NavigationItem.module.scss'

interface IProps {
  Icon?: FC
  item: INavigationItem
  sidebarCollapsed: boolean
  isExpanded: boolean
  onExpand: (id: string) => void
}

const NavigationItem: FC<IProps> = (props) => {
  const { Icon, item, isExpanded, onExpand, sidebarCollapsed } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const containsSubItems = Array.isArray(item.items)
  const showSubItems = isExpanded && !sidebarCollapsed

  const location = useLocation()

  const parentItemActive = item.link === location.pathname
  const childItemActive = useMemo(() => {
    if (parentItemActive) return false

    // When doesn't include sub-items or the navigation bar is not collapsed
    if (!Array.isArray(item.items)) return false

    const links = item.items.map((subItem) => subItem.link)

    return links.includes(location.pathname)
  }, [parentItemActive, location.pathname, sidebarCollapsed])

  const showParentBg = parentItemActive || (childItemActive && sidebarCollapsed)
  const boldText = parentItemActive || childItemActive

  const ParentItem = item.link ? Link : Row

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (item.link) return

    if (sidebarCollapsed) {
      setAnchorEl(e.currentTarget)
      return
    }

    if (containsSubItems) {
      onExpand(item.id)
    }
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleClick({
        currentTarget: event.currentTarget
      } as MouseEvent<HTMLElement>)
    }
  }

  // Hide popup if sidebar is not minimized
  useEffect(() => {
    if (!sidebarCollapsed) {
      setAnchorEl(null)
    }
  }, [sidebarCollapsed])

  const renderParentItem = () => (
    <ParentItem
      to={item.link as string}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      className={clsx(styles.navItem, showParentBg && styles.active)}
      key={item.id}
      tabIndex={0}
    >
      {!!Icon && <Icon />}

      {!sidebarCollapsed && (
        <>
          <Text
            type={TextTypes.TEXT_SM}
            weight={boldText ? FontWeight.BOLD : FontWeight.REGULAR}
            color={Color.gray300}
          >
            {item.title}
          </Text>

          {containsSubItems && (
            <div className={clsx(styles.arrow, isExpanded && styles.opened)}>
              <KeyboardArrowDownOutlinedIcon />
            </div>
          )}
        </>
      )}
    </ParentItem>
  )

  return (
    <div className={styles.navItemWrapper}>
      {sidebarCollapsed ? (
        <Tooltip placement="right" tooltip={item.title}>
          <div>{renderParentItem()}</div>
        </Tooltip>
      ) : (
        renderParentItem()
      )}

      {showSubItems && (
        <div>
          {item.items?.map((subItem) => (
            <Link
              to={subItem.link as string}
              className={clsx(
                styles.navItem,
                styles.subItem,
                location.pathname === subItem.link && styles.active
              )}
              key={subItem.id}
            >
              <Text
                type={TextTypes.TEXT_SM}
                weight={
                  location.pathname === subItem.link
                    ? FontWeight.BOLD
                    : FontWeight.REGULAR
                }
                color={Color.gray300}
              >
                {subItem.title}
              </Text>
            </Link>
          ))}
        </div>
      )}

      {/* A popup which can be open by clicking on menu items which contains
      sub items when navigation is collapsed */}
      {sidebarCollapsed && containsSubItems && (
        <CollapsedNavigationPopover
          name={`popover-${item.id}`}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          title={item.title}
          Icon={Icon as FC}
          items={item.items as INavigationItem[]}
        />
      )}
    </div>
  )
}

export default NavigationItem
