import { TableFilterType } from '@/common/types/enums/table'
import { IFilterItem } from '@/common/types/interfaces/table'
import {
  appointmentTypesList,
  carriersList,
  classesList,
  fuelTypesList
} from '@/common/utils/mappers'

const driversFilters: IFilterItem[] = [
  {
    id: 'appointmentType',
    name: 'Appointment Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    options: appointmentTypesList.map((item) => ({
      id: item,
      title: item
    }))
  },
  {
    id: 'operatingCarrier',
    name: 'Operating Carrier',
    type: TableFilterType.List,
    placeholder: 'Search Carrier',
    options: carriersList.map((item) => ({
      id: item,
      title: item
    }))
  },
  {
    id: 'puClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    options: classesList.map((classItem) => ({
      id: classItem,
      title: classItem
    }))
  },
  {
    id: 'puFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    options: fuelTypesList.map((fuel) => ({
      id: fuel,
      title: fuel
    }))
  },
  {
    id: 'puDwell',
    name: 'Dwell',
    type: TableFilterType.Time
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    options: [
      { id: 'yes', title: 'Yes' },
      { id: 'no', title: 'No' }
    ],
    valuesMapper: {
      yes: true,
      no: false
    }
  }
]

export default driversFilters
