import { useAuth0 } from '@auth0/auth0-react'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import clsx from 'clsx'
import { FC, KeyboardEvent, useEffect, useState } from 'react'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'

import {
  Avatar,
  Col,
  DarkButton,
  FontWeight,
  Logo,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import {
  CollapsedNavigationPopover,
  NavigationItem,
  PortalsDropdownList
} from '@/common/components/molecules'
import {
  enterprisePortalNavigation,
  INavigationItem,
  sitePortalNavigation
} from '@/common/components/organisms/Navigation/navigationItems'
import { BREAKPOINTS } from '@/common/constants'
import { useUserContext } from '@/common/contexts/UserContext'
import { PortalTypes } from '@/common/types/enums/global'
import { Color } from '@/packages/palette'
import { ROUTES, useNavigator } from '@/router'

import styles from './Navigation.module.scss'

interface IProps {
  opened: boolean
  title: string
  toggleOpened: () => void
}

const Navigation: FC<IProps> = (props) => {
  const { opened, toggleOpened, title } = props
  const { portalType, role, brand } = useUserContext()
  const { user } = useAuth0()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const navigate = useNavigator()

  const { width = 0 } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.MD

  const [collapsed, setCollapsed] = useLocalStorage('sidebarCollapsed', false)
  const [expandedItem, setExpandedItem] = useState<string | undefined>()

  const navItems =
    portalType === PortalTypes.Site
      ? sitePortalNavigation
      : enterprisePortalNavigation

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      setCollapsed(!collapsed)
    }
  }

  const handleExpandItem = (id: string) => {
    setExpandedItem(id === expandedItem ? undefined : id)
  }

  useEffect(() => {
    if (isSmallView && collapsed && opened) {
      setCollapsed(false)
    }
  }, [width, opened])

  return (
    <>
      {opened && <div className={styles.blackBackground} />}
      <nav
        className={clsx(
          styles.nav,
          collapsed && styles.collapsed,
          opened && styles.opened
        )}
      >
        <Row items="center" gap={12} className={styles.navHeader}>
          <Logo
            brand={brand}
            className={styles.logo}
            onClick={() => navigate.to('/')}
          />
          <DarkButton
            onClick={toggleOpened}
            id="close-menu"
            className={styles.closeMenuButton}
          >
            <CloseIcon />
          </DarkButton>

          <Col gap={3}>
            <Text
              type={isSmallView ? TextTypes.TEXT_XL : TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray0}
              className={styles.brandName}
            >
              {isSmallView ? title : brand}
            </Text>

            {!isSmallView && (
              <Text
                type={TextTypes.TEXT_XS}
                color={Color.gray500}
                className={styles.brandName}
              >
                YardIQ
              </Text>
            )}
          </Col>
        </Row>

        {isSmallView && <PortalsDropdownList />}

        <div className={styles.navItems}>
          {/* @ts-ignore */}
          {navItems[role].map(({ icon: Icon, ...item }: INavigationItem) => (
            <NavigationItem
              key={item.id}
              item={item}
              Icon={Icon}
              isExpanded={expandedItem === item.id}
              onExpand={handleExpandItem}
              sidebarCollapsed={collapsed}
            />
          ))}
        </div>

        <Col gap={20} items="stretch" className={styles.navFooter}>
          <Row
            items="center"
            gap={8}
            tabIndex={0}
            clickable
            className={styles.collapseButton}
            onKeyDown={handleKeyDown}
            onClick={() => setCollapsed(!collapsed)}
          >
            <KeyboardDoubleArrowLeftOutlinedIcon />

            <Text type={TextTypes.TEXT_SM} color={Color.gray300}>
              Collapse
            </Text>
          </Row>

          <Row
            items="center"
            gap={8}
            className={styles.userInfo}
            clickable
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <Avatar name={user?.name as string} size="md" />

            <Text
              type={TextTypes.TEXT_SM}
              color={Color.gray300}
              className="tw-whitespace-nowrap"
            >
              {user?.email}
            </Text>
          </Row>

          <CollapsedNavigationPopover
            name="popover-user-profile"
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            title={user?.name as string}
            className="!tw-ml-[40px]"
            /* eslint-disable-next-line react/no-unstable-nested-components */
            Icon={() => <Avatar name={user?.name as string} size="md" />}
            items={[
              { id: 'settings', title: 'Settings', link: ROUTES.PROFILE },
              { id: 'help', title: 'Help', link: '/' },
              { id: 'logout', title: 'Logout', link: ROUTES.LOGOUT }
            ]}
          />
        </Col>
      </nav>
    </>
  )
}

export default Navigation
