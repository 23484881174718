import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FC, useState } from 'react'

import { Button, Col, Select, Text, TextTypes } from '@/common/components/atoms'
import { Modal } from '@/common/components/organisms'
import { IModalWithCloseFn } from '@/common/types/interfaces/ui'
import { DELETE_REASONS } from '@/features/gate/constants'
import { Color } from '@/packages/palette'

interface IProps extends IModalWithCloseFn {
  onDelete: () => void
}

const DeleteQueueItemModal: FC<IProps> = (props) => {
  const { onDelete, closeModal } = props

  const [reason, setReason] = useState<string | undefined>()

  return (
    <Modal
      title="Delete Truck Card"
      closeModal={closeModal}
      placement="center"
      footer={
        <Button
          type="danger"
          onClick={onDelete}
          disabled={!reason}
          startIcon={<DeleteOutlineIcon />}
        >
          Delete Truck Card
        </Button>
      }
    >
      <Col gap={8}>
        <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
          Are you sure you want to delete this card? This will remove them from
          the queue and cannot be undone.
        </Text>

        <Select
          required
          label="Select Reason"
          value={reason}
          options={DELETE_REASONS}
          onChange={(value) => setReason(value as string)}
        />
      </Col>
    </Modal>
  )
}

export default DeleteQueueItemModal
