import { useAuth0 } from '@auth0/auth0-react'
import { FC, PropsWithChildren, useEffect } from 'react'

import { useNavigator } from '@/router/Navigator'

const RouteDispatcher: FC<PropsWithChildren> = (props) => {
  const { children } = props
  const navigate = useNavigator()

  const { user, loginWithRedirect, isLoading } = useAuth0()

  useEffect(() => {
    if (!user && !isLoading) {
      loginWithRedirect()
      return
    }

    if (window.location.hash.includes('#!')) {
      navigate.to(
        `/${window.location.hash
          .split('/')
          .filter((item) => item !== '#!')
          .join('/')}`
      )
    }
  }, [user, isLoading])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <>{children}</>
}

export default RouteDispatcher
