import { FC } from 'react'

import { Col, Text, TextTypes } from '@/common/components/atoms'
import { TransactionDirectionTypes } from '@/common/types/enums/transactionDetails'
import { Color } from '@/packages/palette'

interface IProps {
  type: TransactionDirectionTypes
}

const EmptyQueue: FC<IProps> = (props) => {
  const { type } = props

  return (
    <Col
      items="center"
      justify="center"
      className="tw-w-full tw-h-full"
      gap={5}
    >
      <Text type={TextTypes.TEXT_LG} color={Color.gray700}>
        There is nothing in the queue.
      </Text>
      <Text type={TextTypes.TEXT_MD} color={Color.gray600}>
        Power units ready to be checked-
        {type === TransactionDirectionTypes.CheckIn ? 'in' : 'out'} will appear
        here.
      </Text>
    </Col>
  )
}

export default EmptyQueue
