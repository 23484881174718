import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FC } from 'react'

import { Button, Text, TextTypes } from '@/common/components/atoms'
import { Modal } from '@/common/components/organisms'
import { IModalWithCloseFn } from '@/common/types/interfaces/ui'
import { Color } from '@/packages/palette'

interface IProps extends IModalWithCloseFn {
  onDelete: () => void
  name: string
}

const DeleteUserModal: FC<IProps> = (props) => {
  const { closeModal, onDelete, name } = props

  return (
    <Modal
      title="Delete User"
      closeModal={closeModal}
      footer={
        <Button
          type="danger"
          startIcon={<DeleteOutlineIcon />}
          onClick={onDelete}
        >
          Delete User
        </Button>
      }
    >
      <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
        Are you sure you want to delete the user <b>{name}</b>? <br />
        <br /> They will lose access to the site immediately and this cannot be
        undone.
      </Text>
    </Modal>
  )
}

export default DeleteUserModal
