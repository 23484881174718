import { FC } from 'react'

import {
  Col,
  GroupByDropdown,
  Row,
  SearchInput
} from '@/common/components/atoms'
import { FilterDropdown } from '@/common/components/molecules'
import { useTableContext } from '@/common/contexts/TableContext'

import styles from './TableFilters.module.scss'

interface IProps {
  withSearch: boolean
}

const TableFilters: FC<IProps> = (props) => {
  const { withSearch } = props
  const {
    filters,
    searchValue,
    searchPlaceholder,
    groupByOptions,
    onSearchChange
  } = useTableContext()

  return (
    <Col>
      <div id="filters-portal" className="tw-overflow-y-auto" />
      <Row justify="between" gap={20} className={styles.tableFilters}>
        <Row items="stretch" gap={8}>
          {Array.isArray(groupByOptions) && <GroupByDropdown />}

          {Array.isArray(filters) && <FilterDropdown />}
        </Row>

        {withSearch && (
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchValue}
            onUpdate={onSearchChange}
          />
        )}
      </Row>
    </Col>
  )
}

export default TableFilters
