import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { Col, FontWeight, Text, TextTypes } from '@/common/components/atoms'
import { Color } from '@/packages/palette'

import styles from './CardRow.module.scss'

interface IProps {
  titleOne: string
  titleTwo?: string
  valueOne: string | null | undefined
  valueTwo?: string | null | undefined
  span?: string
  button?: ReactNode
  className?: string
}

const CardRow: FC<IProps> = (props) => {
  const { titleOne, titleTwo, valueOne, className, valueTwo, span, button } =
    props

  const firstColumn = () => {
    const noValue =
      valueOne === null ? '-' : <div className={styles.progressBar} />

    return (
      <Col gap={4}>
        <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
          {titleOne}
        </Text>

        {valueOne ? (
          <Text
            type={TextTypes.TEXT_SM}
            color={Color.gray700}
            weight={span ? FontWeight.BOLD : FontWeight.REGULAR}
          >
            {valueOne}
          </Text>
        ) : (
          noValue
        )}
      </Col>
    )
  }

  const secondColumn = () => {
    if (!titleTwo) return button

    const noValue =
      valueTwo === null ? '-' : <div className={styles.progressBar} />

    return (
      <Col gap={4}>
        <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
          {titleTwo}
        </Text>

        {valueTwo ? (
          <Text
            type={TextTypes.TEXT_SM}
            color={Color.gray700}
            weight={span ? FontWeight.BOLD : FontWeight.REGULAR}
          >
            {valueTwo}{' '}
            {!!span && <span className={styles.duration}>({span})</span>}
          </Text>
        ) : (
          noValue
        )}
      </Col>
    )
  }

  return (
    <div className={clsx(styles.gridRow, className)}>
      {firstColumn()}
      {secondColumn()}
    </div>
  )
}

export default CardRow
