import { Tooltip } from '@mui/material'
import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react'

import styles from './Tooltip.module.scss'

interface IProps {
  placement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  tooltip: ReactNode
}

const CustomTooltip: FC<IProps & PropsWithChildren> = (props) => {
  const { placement, children, tooltip } = props

  return (
    <Tooltip
      arrow
      title={tooltip}
      placement={placement}
      className={styles.content}
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
    >
      {children as ReactElement}
    </Tooltip>
  )
}

export default CustomTooltip
