import clsx from 'clsx'
import { FC } from 'react'

import { ReactComponent as NFILogo } from '@/assets/icons/nfi-logo.svg'
import { ReactComponent as RyderLogo } from '@/assets/icons/ryder-logo.svg'
import { ReactComponent as TerminalLogo } from '@/assets/icons/terminal-logo.svg'
import { ReactComponent as TerminalBigLogo } from '@/assets/icons/terminal-wide-logo.svg'
import { Brands } from '@/common/types/enums/global'

import styles from './Logo.module.scss'

interface IProps {
  brand: Brands
  size?: number
  mini?: boolean
  className?: string
  onClick?: () => void
  color?: 'white' | 'black' | 'green' | 'gray' | 'green-white' | 'green-black'
}

const Logo: FC<IProps> = (props) => {
  const {
    brand,
    size = 40,
    mini = true,
    onClick,
    color = 'white',
    className
  } = props

  const getLogo = () => {
    switch (brand) {
      case Brands.Terminal: {
        return mini ? (
          <TerminalLogo width={size} height={size} />
        ) : (
          <TerminalBigLogo width={size} height="auto" />
        )
      }

      case Brands.Ryder: {
        return <RyderLogo width={size} height={size} />
      }

      case Brands.NFI: {
        return <NFILogo width={size} height={size} />
      }

      default: {
        return null
      }
    }
  }

  return (
    <div
      onClick={onClick}
      className={clsx(
        className,
        styles.logoWrapper,
        brand === Brands.Terminal && styles[color]
      )}
    >
      {getLogo()}
    </div>
  )
}

export default Logo
