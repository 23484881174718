import { Popover } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { INavigationItem } from '@/common/components/organisms/Navigation/navigationItems'
import { Color } from '@/packages/palette'

import styles from './CollapsedNavigationPopover.module.scss'

interface IProps {
  name: string
  anchorEl: HTMLElement | null
  onClose: () => void
  title: string
  Icon: FC
  items: INavigationItem[]
  className?: string
}

const CollapsedNavigationPopover: FC<IProps> = (props) => {
  const { name, anchorEl, onClose, title, items, Icon, className } = props

  const location = useLocation()

  const open = Boolean(anchorEl)
  const id = open ? name : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{ paper: clsx(styles.navigationItemPopover, className) }}
    >
      <Row items="center" gap={8} className={styles.popoverHeader}>
        <Icon />

        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.REGULAR}
          color={Color.gray300}
        >
          {title}
        </Text>
      </Row>

      <Col items="stretch" className="tw-py-8">
        {items.map((item) => (
          <Link
            to={item.link as string}
            key={item.id}
            className={clsx(
              styles.subItemLink,
              location.pathname === item.link && styles.active
            )}
            onClick={onClose}
          >
            <Text
              type={TextTypes.TEXT_SM}
              weight={
                location.pathname === item.link
                  ? FontWeight.BOLD
                  : FontWeight.REGULAR
              }
              color={Color.gray300}
            >
              {item.title}
            </Text>
          </Link>
        ))}
      </Col>
    </Popover>
  )
}

export default CollapsedNavigationPopover
