import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Col,
  FontWeight,
  Input,
  Row,
  Select,
  Switch,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { Modal } from '@/common/components/organisms'
import { IUser } from '@/common/types/interfaces/global'
import { IModalWithCloseFn } from '@/common/types/interfaces/ui'
import { rolesByPortalList } from '@/common/utils/data'
import { EditUserSchema } from '@/features/user-management/utils'
import { Color } from '@/packages/palette'

import styles from './AddEditUserModal.module.scss'

interface IProps extends IModalWithCloseFn {
  editMode?: boolean
  selectedItem?: IUser
}

const sites = [
  { id: 'site1', label: 'Site 1' },
  { id: 'site2', label: 'Site 2' },
  { id: 'site3', label: 'Site 3' }
]

const AddEditUserModal: FC<IProps> = (props) => {
  const { closeModal, editMode, selectedItem } = props

  const {
    watch,
    trigger,
    register,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(EditUserSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      firstName: selectedItem?.name?.split?.(' ')[0],
      lastName: selectedItem?.name?.split?.(' ')[1],
      email: selectedItem?.email,
      enterpriseEnabled: !!selectedItem?.enterpriseRoles?.length,
      siteEnabled: !!selectedItem?.siteRoles?.length,
      enterpriseRoles: selectedItem?.enterpriseRoles || [],
      siteRoles: selectedItem?.siteRolesList || []
    }
  })

  const siteEnabled = !!watch('siteEnabled')
  const enterpriseEnabled = !!watch('enterpriseEnabled')
  const enterpriseRoles = watch('enterpriseRoles') || []
  const siteRoles = watch('siteRoles') || []

  const addSiteItem = () => {
    setValue('siteRoles', [
      ...siteRoles,
      {
        id: Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(2, 10),
        site: undefined,
        roles: []
      }
    ])
  }

  const togglePortalEnabled = (type: 'enterpriseEnabled' | 'siteEnabled') => {
    setValue(type, !watch(type))

    if (type === 'siteEnabled' && !siteEnabled && !siteRoles.length) {
      addSiteItem()
    }
  }

  const updateSite = (
    id: string,
    field: 'site' | 'roles',
    value: string | string[] | undefined
  ) => {
    setValue(
      'siteRoles',
      siteRoles.map((item) =>
        item.id === id
          ? {
              ...item,
              [field]:
                field === 'site' ? (value as string) : (value as string[])
            }
          : item
      )
    )
  }

  const removeSite = (id: string) => {
    setValue(
      'siteRoles',
      siteRoles.filter((item) => item.id !== id)
    )

    if (siteRoles.length === 1) {
      setValue('siteEnabled', false)
    }
  }

  const filterOutSiteOptions = (id: string) =>
    sites.filter((site) =>
      siteRoles.every((item) => item.id === id || item.site !== site.id)
    )

  const onSubmit = () => {
    trigger()
  }

  useEffect(() => {
    clearErrors('enterpriseRoles')
  }, [enterpriseRoles])

  useEffect(() => {
    clearErrors('siteRoles')
  }, [siteRoles])

  return (
    <Modal
      closeModal={closeModal}
      placement="right"
      className="tw-w-[536px]"
      title={editMode ? 'Edit User' : 'Add User'}
      footer={
        <Button action="submit" type="primary" onClick={onSubmit}>
          Save User
        </Button>
      }
    >
      <Col gap={8} items="stretch">
        <Text
          type={TextTypes.TEXT_LG}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          About
        </Text>

        <Row gap={8}>
          <Input
            {...register('firstName')}
            fullWidth
            required
            label="First Name"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
          <Input
            {...register('lastName')}
            fullWidth
            required
            label="Last Name"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Row>

        <Input
          {...register('email')}
          required
          label="Email"
          type="email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <Button type="text" className="tw-self-start !tw-px-0">
          Reset Password
        </Button>
      </Col>

      <Col gap={8} items="stretch" className="tw-mt-24">
        <Text
          type={TextTypes.TEXT_LG}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Roles
        </Text>

        <Switch
          value={enterpriseEnabled}
          label="Enterprise Portal"
          onChange={() => togglePortalEnabled('enterpriseEnabled')}
        />

        {enterpriseEnabled && (
          <Col className={styles.roleListContainer}>
            <Select
              multiple
              required
              label="Role"
              fullWidth={false}
              value={enterpriseRoles}
              options={rolesByPortalList.enterprise}
              error={!!errors.enterpriseRoles}
              helperText={errors.enterpriseRoles?.message}
              onChange={(value) =>
                setValue('enterpriseRoles', value as string[])
              }
            />
          </Col>
        )}

        <Switch
          value={siteEnabled}
          label="Site Portal"
          onChange={() => togglePortalEnabled('siteEnabled')}
        />

        {siteEnabled && (
          <>
            <Col className={styles.roleListContainer} gap={8}>
              {siteRoles.map((item, index) => (
                <Row gap={8} key={item.id}>
                  <Select
                    required
                    label="Site"
                    value={item.site}
                    options={filterOutSiteOptions(item.id)}
                    // @ts-ignore
                    error={!!errors?.siteRoles?.[`${index}`]?.site}
                    // @ts-ignore
                    helperText={errors?.siteRoles?.[`${index}`]?.site?.message}
                    onChange={(value) => updateSite(item.id, 'site', value)}
                  />

                  <Select
                    multiple
                    required
                    label="Role"
                    value={item.roles}
                    options={rolesByPortalList.site}
                    // @ts-ignore
                    error={!!errors.siteRoles?.[`${index}`]?.roles}
                    // @ts-ignore
                    helperText={errors.siteRoles?.[`${index}`]?.roles?.message}
                    onChange={(value) => updateSite(item.id, 'roles', value)}
                  />

                  <div
                    tabIndex={0}
                    className={styles.deleteIcon}
                    onClick={() => removeSite(item.id)}
                  >
                    <DeleteOutlineIcon />
                  </div>
                </Row>
              ))}
            </Col>

            {siteRoles.length < sites.length && (
              <Button
                type="outlined"
                startIcon={<AddIcon />}
                className="tw-self-start"
                onClick={addSiteItem}
              >
                Add Site
              </Button>
            )}
          </>
        )}
      </Col>
    </Modal>
  )
}

export default AddEditUserModal
