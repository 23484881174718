import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import clsx from 'clsx'
import React, { FC, useEffect, useMemo, useState } from 'react'

import { Col, Text, TextTypes } from '@/common/components/atoms'
import Footer from '@/common/components/atoms/Footer/Footer'
import {
  TableFilters,
  TablePagination,
  TableRow as CustomTableRow
} from '@/common/components/molecules'
import { TableContextProvider } from '@/common/contexts/TableContext'
import { TableActions } from '@/common/types/enums/table'
import {
  IFilterItem,
  IFilterValues,
  IGroupByItem,
  IHighOrderColumn,
  ITableColumn
} from '@/common/types/interfaces/table'
import { filter, search, sort } from '@/common/utils/table'
import { Color } from '@/packages/palette'

import styles from './Table.module.scss'

interface IProps {
  name: string
  columns: (groupBy: string | undefined) => ITableColumn[]
  highOrderColumns?: (groupBy: string | undefined) => IHighOrderColumn[]
  rows: any[]
  idAccessor: string
  clickable?: boolean
  defaultFilters?: IFilterValues
  filters?: IFilterItem[]
  searchPlaceholder?: string
  searchFields?: string[]
  groupByOptions?: IGroupByItem[]
  isRowInactive?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  groupingHelper?: (groupBy: string, rows: any[]) => any[]
}

type Order = 'asc' | 'desc' | undefined

const CustomTable: FC<IProps> = (props) => {
  const {
    name,
    idAccessor,
    filters,
    groupByOptions,
    columns,
    highOrderColumns,
    isRowInactive,
    searchFields,
    searchPlaceholder = 'Search',
    clickable,
    handleAction,
    defaultFilters,
    groupingHelper,
    rows = []
  } = props

  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [groupBy, setGroupBy] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<IFilterValues>({})

  const [orderBy, setOrderBy] = useState<string | undefined>()
  const [order, setOrder] = useState<Order>(undefined)

  const withSearch = !!searchFields
  const withFilters = withSearch || !!groupByOptions || !!filters

  const memoCols: ITableColumn[] = useMemo(() => columns(groupBy), [groupBy])
  const memoHighOrderCols: IHighOrderColumn[] | undefined = useMemo(
    () => highOrderColumns?.(groupBy),
    [groupBy]
  )

  const memoRows: any[] = useMemo(() => {
    const withSearchApplied = search(searchFields, searchValue, rows)
    const filtered = filters?.length
      ? filter(filters, filterValues, withSearchApplied)
      : withSearchApplied
    const grouped = groupBy
      ? groupingHelper?.(groupBy, filtered) || []
      : filtered

    if (orderBy && order) {
      return sort({ rows: grouped, columns: memoCols, order, orderBy, groupBy })
    }

    return grouped
  }, [rows, groupBy, searchValue, filterValues, orderBy, order])

  const providerValue = useMemo(
    () => ({
      searchValue,
      filterValues,
      groupBy,
      searchPlaceholder,

      filters,
      groupByOptions,

      isRowInactive,
      setFilterValues,
      handleAction,
      onSearchChange: setSearchValue,
      onGroupByChange: setGroupBy
    }),
    [
      searchValue,
      filterValues,
      groupBy,
      filters,
      groupByOptions,
      handleAction,
      isRowInactive,
      searchPlaceholder
    ]
  )

  const clearFilters = () => {
    setSearchValue('')
    setFilterValues({})
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    if (orderBy !== property) {
      setOrderBy(property)
      setOrder('asc')
    } else if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrderBy(undefined)
      setOrder(undefined)
    }
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilterValues(defaultFilters)
    }
  }, [defaultFilters])

  return (
    <TableContextProvider value={providerValue}>
      <Col items="stretch" className="tw-self-stretch tw-w-full">
        {withFilters && <TableFilters withSearch={withSearch} />}

        {memoRows.length ? (
          <div className={styles.tableWrapper}>
            <TableContainer className={styles.tableContainer}>
              <Table
                size="medium"
                aria-labelledby={name}
                classes={{
                  root: clsx(
                    styles.table,
                    withFilters && styles.borderTop,
                    clickable && styles.clickable
                  )
                }}
              >
                <TableHead>
                  {!!memoHighOrderCols?.length && (
                    <TableRow>
                      {memoHighOrderCols.map((column) => (
                        <TableCell
                          key={column.id}
                          colSpan={column.colSpan}
                          variant="head"
                          align="left"
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                  <TableRow>
                    {memoCols.map((column) => (
                      <TableCell
                        key={column.id}
                        variant="head"
                        align="left"
                        sortDirection={orderBy === column.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={(e) => handleRequestSort(e, column.id)}
                        >
                          {column.title}
                          {orderBy === column.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {memoRows.map((row) => (
                    <CustomTableRow
                      key={row[idAccessor]}
                      row={row}
                      columns={memoCols}
                      idAccessor={idAccessor}
                      clickable={clickable}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Footer>
              <TablePagination count={memoRows.length} />
            </Footer>
          </div>
        ) : (
          <Col items="center" justify="center" gap={8} className="tw-flex-1">
            <Text type={TextTypes.TEXT_LG} color={Color.gray700}>
              No results found.
            </Text>
            <Text type={TextTypes.TEXT_MD} color={Color.gray600}>
              Try searching or filtering by different criteria.{' '}
              <span
                className="color-green500 tw-cursor-pointer"
                onClick={clearFilters}
              >
                Clear Filters
              </span>
            </Text>
          </Col>
        )}
      </Col>
    </TableContextProvider>
  )
}

export default CustomTable
