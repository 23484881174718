import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Input, Row } from '@/common/components/atoms'
import { IsrVisitSchema } from '@/features/isr/utils'

const VisitDetailsDriverDetails = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof IsrVisitSchema>>()

  return (
    <Row gap={16}>
      <Input
        {...register('driverName')}
        fullWidth
        required
        label="Driver Name"
        error={!!errors.driverName}
        helperText={errors.driverName?.message}
      />

      <Input
        {...register('driverLicenseNum')}
        uppercase
        fullWidth
        required
        label="License"
        error={!!errors.driverLicenseNum}
        helperText={errors.driverLicenseNum?.message}
      />
    </Row>
  )
}

export default VisitDetailsDriverDetails
