import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import clsx from 'clsx'

import { Row } from '@/common/components/atoms'
import { useUserContext } from '@/common/contexts/UserContext'
import { WithHeaderTemplate } from '@/common/templates'
import {
  Brands,
  EnterpriseRoles,
  PortalTypes,
  SiteRoles
} from '@/common/types/enums/global'

const TemporaryScreen = () => {
  const { brand, updateBrand, updateRole, portalType, role } = useUserContext()

  return (
    <WithHeaderTemplate>
      <Row items="start" gap={20} className="tw-p-20">
        <FormControl>
          <InputLabel id="demo-brand-select-label">Brand</InputLabel>
          <Select
            labelId="demo-brand-select-label"
            value={brand}
            label="Brand"
            onChange={(e) => updateBrand(e.target.value as Brands)}
          >
            <MenuItem value={Brands.Terminal}>{Brands.Terminal}</MenuItem>
            <MenuItem value={Brands.Ryder}>{Brands.Ryder}</MenuItem>
            <MenuItem value={Brands.NFI}>{Brands.NFI}</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-role-select-label">Role</InputLabel>
          <Select
            labelId="demo-role-select-label"
            value={role}
            label="Role"
            onChange={(e) => updateRole(e.target.value as SiteRoles)}
          >
            <MenuItem
              value={SiteRoles.Admin}
              className={clsx(
                portalType === PortalTypes.Enterprise && '!tw-hidden'
              )}
            >
              Admin
            </MenuItem>
            <MenuItem
              value={SiteRoles.ISRManager}
              className={clsx(
                portalType === PortalTypes.Enterprise && '!tw-hidden'
              )}
            >
              ISR Manager
            </MenuItem>
            <MenuItem
              value={SiteRoles.Gate}
              className={clsx(
                portalType === PortalTypes.Enterprise && '!tw-hidden'
              )}
            >
              Gate
            </MenuItem>
            <MenuItem
              value={SiteRoles.ViewOnly}
              className={clsx(
                portalType === PortalTypes.Enterprise && '!tw-hidden'
              )}
            >
              View Only
            </MenuItem>

            <MenuItem
              value={EnterpriseRoles.Admin}
              className={clsx(portalType === PortalTypes.Site && '!tw-hidden')}
            >
              Admin
            </MenuItem>
          </Select>
        </FormControl>
      </Row>
    </WithHeaderTemplate>
  )
}

export default TemporaryScreen
