import { Slide, Snackbar, SnackbarProps } from '@mui/material'
import { FC, ReactNode } from 'react'

import { Row, Text, TextTypes } from '@/common/components/atoms'
import { Color } from '@/packages/palette'

import styles from './Snackbar.module.scss'

interface IProps extends SnackbarProps {
  icon?: ReactNode
  onClose?: () => void
}

const CustomSnackbar: FC<IProps> = (props) => {
  const {
    open,
    icon,
    message,
    onClose,
    autoHideDuration = 3000,
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
    ...rest
  } = props

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    onClose?.()
  }

  return (
    <Snackbar
      {...rest}
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Slide}
    >
      <div className={styles.snackbar}>
        <Row items="center" gap={8}>
          {icon}

          <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
            {message}
          </Text>
        </Row>
      </div>
    </Snackbar>
  )
}

export default CustomSnackbar
