import { FC, PropsWithChildren } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Portal } from '@/common/components/atoms'
import { BREAKPOINTS } from '@/common/constants'

import styles from './HeaderActionPortal.module.scss'

const HeaderActionPortal: FC<PropsWithChildren> = (props) => {
  const { children } = props

  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.MD

  if (isSmallView) {
    return <div className={styles.actionWrapper}>{children}</div>
  }

  return <Portal wrapperId="header-actions">{children}</Portal>
}

export default HeaderActionPortal
