import AddIcon from '@mui/icons-material/Add'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button, Col, HeaderActionPortal } from '@/common/components/atoms'
import { Stats } from '@/common/components/molecules'
import { Table } from '@/common/components/organisms'
import { useModal } from '@/common/hooks'
import { WithHeaderTemplate } from '@/common/templates'
import { TableActions } from '@/common/types/enums/table'
import { IUser } from '@/common/types/interfaces/global'
import { IStatsItem } from '@/common/types/interfaces/ui'
import { getDateDifference } from '@/common/utils/helpers'
import { AddEditUserModal, DeleteUserModal } from '@/features/user-management'
import {
  generateUserManagementStats,
  prepareUserManagementUrlFilters,
  userManagementColumns,
  userManagementFilters,
  userManagementGroupBy,
  userManagementGroupingHelper,
  userManagementMockData
} from '@/features/user-management/utils'

const UserManagementTemplate = () => {
  const [searchParams] = useSearchParams()

  const roleFilter = searchParams.get('role')
  const inactivityFilter = searchParams.get('inactivity')

  const {
    visible,
    openModal,
    closeModal: close
  } = useModal<'delete' | 'user'>()

  const [data, setData] = useState(() => userManagementMockData)
  const [selectedItem, setSelectedItem] = useState<IUser | undefined>()

  const defaultFilters = useMemo(
    () => prepareUserManagementUrlFilters(roleFilter, inactivityFilter),
    [roleFilter, inactivityFilter]
  )

  const statsItems: IStatsItem[] = useMemo(
    () => generateUserManagementStats(data),
    [data]
  )

  const closeModal = () => {
    close()
    setSelectedItem(undefined)
  }

  const onDelete = () => {
    if (!selectedItem) return

    setData((prev) => prev.filter((item) => item.id !== selectedItem.id))
    closeModal()
  }

  const handleAction = (action: TableActions, item: IUser) => {
    switch (action) {
      case TableActions.Delete: {
        openModal('delete')
        setSelectedItem(item)
        break
      }

      case TableActions.Edit:
      case TableActions.RowClick: {
        openModal('user')
        setSelectedItem(item)
        break
      }

      default: {
        break
      }
    }
  }

  const isRowInactive = (row: IUser) =>
    row.lastActivity
      ? getDateDifference(new Date(), row.lastActivity) >= 90
      : false

  return (
    <WithHeaderTemplate>
      {visible === 'delete' && (
        <DeleteUserModal
          onDelete={onDelete}
          closeModal={closeModal}
          name={selectedItem?.name as string}
        />
      )}

      {visible === 'user' && (
        <AddEditUserModal
          selectedItem={selectedItem}
          editMode={!!selectedItem}
          closeModal={closeModal}
        />
      )}

      <Col items="stretch" className="tw-h-full">
        <HeaderActionPortal>
          <Button
            type="primary"
            startIcon={<AddIcon />}
            onClick={() => openModal('user')}
          >
            New User
          </Button>
        </HeaderActionPortal>

        <Stats items={statsItems} className="tw-grid-cols-4" />

        <div className="tw-flex-1 tw-flex">
          <Table
            clickable
            rows={data}
            name="users"
            idAccessor="id"
            searchFields={['name']}
            searchPlaceholder="Search Users"
            handleAction={handleAction}
            isRowInactive={isRowInactive}
            filters={userManagementFilters}
            defaultFilters={defaultFilters}
            columns={userManagementColumns}
            groupByOptions={userManagementGroupBy}
            groupingHelper={userManagementGroupingHelper}
          />
        </div>
      </Col>
    </WithHeaderTemplate>
  )
}

export default UserManagementTemplate
