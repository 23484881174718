import { faker } from '@faker-js/faker'

import { TRUCK_IMAGES } from '@/common/constants'
import {
  accountsList,
  appointmentTypesList,
  cargoAssetTypesList,
  carriersList,
  classesList,
  emissionTypeMapper,
  fuelTypesList,
  loadStatusesList
} from '@/common/utils/mappers'
import { IDriverItem } from '@/features/gate/types'

const generateMockData = (): IDriverItem => {
  const transactionTime = faker.date.recent()
  const now = new Date()

  const puFuelType = faker.helpers.arrayElement(fuelTypesList)
  const emissionType = emissionTypeMapper[puFuelType]

  return {
    id: faker.datatype.uuid(),
    lane: 1,
    returnVisit: true,
    img: TRUCK_IMAGES[0],

    transactionTime,

    puName: faker.random.alphaNumeric(9).toUpperCase(),
    puLPN: faker.vehicle.vrm(),
    puLPNState: faker.address.stateAbbr(),
    puClass: faker.helpers.arrayElement(classesList),
    puFuelType,
    emissionType,
    puDwell: now.getTime() - transactionTime.getTime(),
    puVIN: faker.vehicle.vin(),
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),

    appointmentType: faker.helpers.arrayElement(appointmentTypesList),
    operatingCarrier: faker.helpers.arrayElement(carriersList),
    account: [faker.helpers.arrayElement(accountsList)],

    driverName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    driverLicenseNum: faker.random.alphaNumeric(9).toUpperCase(),
    driverPhoneNum: faker.phone.number('(###) ###-####'),

    assetName: faker.random.alphaNumeric(9).toUpperCase(),
    assetType: faker.helpers.arrayElement(cargoAssetTypesList),
    assetCarrier: faker.helpers.arrayElement(carriersList),
    arrivingLoadStatus: faker.helpers.arrayElement(loadStatusesList),
    shipmentNumber: faker.random.alphaNumeric(9).toUpperCase(),
    chassisName: faker.datatype
      .number({ min: 100000000, max: 999999999 })
      .toString(),
    sealNumber: faker.random.alphaNumeric(9).toUpperCase(),
    sealMatchPW: faker.datatype.boolean(),

    mismatch: faker.datatype.boolean()
  }
}

const driversMockData: IDriverItem[] = Array.from(
  { length: 10 },
  generateMockData
)

export default driversMockData
