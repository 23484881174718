import { TableColumnType } from '@/common/types/enums/table'
import { ITableColumn } from '@/common/types/interfaces/table'
import {
  userFriendlyBoolean,
  userFriendlyEmissionType,
  userFriendlyState
} from '@/common/utils/table'

const transactionsColumns = (): ITableColumn[] => [
  // TRANSACTION
  {
    id: 'transactionTime',
    title: 'Transaction Time',
    type: TableColumnType.Date
  },
  {
    id: 'direction',
    title: 'Direction',
    type: TableColumnType.Text
  },
  {
    id: 'eventType',
    title: 'Event Type',
    type: TableColumnType.Text
  },
  {
    id: 'appointmentType',
    title: 'Appointment Type',
    type: TableColumnType.Text
  },

  // POWER UNIT
  {
    id: 'puName',
    title: 'ID',
    type: TableColumnType.Text
  },
  {
    id: 'puLPN',
    title: 'LPN',
    type: TableColumnType.Text
  },
  {
    id: 'puLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState
  },
  {
    id: 'usDOT',
    title: 'USDOT',
    type: TableColumnType.Text
  },
  {
    id: 'mcNum',
    title: 'MC #',
    type: TableColumnType.Text
  },
  {
    id: 'puVIN',
    title: 'VIN',
    type: TableColumnType.Text
  },
  {
    id: 'puClass',
    title: 'Class',
    type: TableColumnType.Text
  },
  {
    id: 'puFuelType',
    title: 'Fuel Type',
    type: TableColumnType.Text
  },
  {
    id: 'emissionType',
    title: 'Emission',
    type: TableColumnType.Text,
    converter: userFriendlyEmissionType
  },
  {
    id: 'operatingCarrier',
    title: 'Carrier',
    type: TableColumnType.Text
  },
  {
    id: 'account',
    title: 'Account',
    type: TableColumnType.Text
  },

  // DRIVER DETAILS
  {
    id: 'driverName',
    title: 'Name',
    type: TableColumnType.Text
  },
  {
    id: 'driverLicenseNum',
    title: 'License',
    type: TableColumnType.Text
  },
  {
    id: 'driverPhoneNum',
    title: 'Phone #',
    type: TableColumnType.Phone
  },

  // CARGO ASSET
  {
    id: 'assetName',
    title: 'ID',
    type: TableColumnType.Text
  },
  {
    id: 'assetLPN',
    title: 'LPN',
    type: TableColumnType.Text
  },
  {
    id: 'assetLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState
  },
  {
    id: 'assetCarrier',
    title: 'Asset Carrier',
    type: TableColumnType.Text
  },
  {
    id: 'assetType',
    title: 'Asset Type',
    type: TableColumnType.Text
  },
  {
    id: 'loadStatus',
    title: 'Load Status',
    type: TableColumnType.Text
  },
  {
    id: 'shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text
  },
  {
    id: 'sealNumber',
    title: 'Seal #',
    type: TableColumnType.Text
  },
  {
    id: 'sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean
  },
  {
    id: 'source',
    title: 'Source',
    type: TableColumnType.Text
  },
  {
    id: 'mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean
  }
]

export default transactionsColumns
