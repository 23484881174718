import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined'
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import { FC } from 'react'

import { Button, Row, Text, TextTypes } from '@/common/components/atoms'
import { Color } from '@/packages/palette'

interface IProps {
  count: number
}

const TablePagination: FC<IProps> = (props) => {
  const { count } = props

  const buttonClassName = 'tw-w-[24px] tw-h-[24px] !tw-p-0'

  return (
    <Row items="center" gap={8}>
      <Text type={TextTypes.TEXT_XS} color={Color.gray700}>
        1-{count} of {count}
      </Text>

      <Row items="stretch" gap={4}>
        <Button type="outlined" className={buttonClassName}>
          <SkipPreviousOutlinedIcon />
        </Button>

        <Button type="outlined" className={buttonClassName}>
          <NavigateBeforeOutlinedIcon />
        </Button>

        <Button type="outlined" className={buttonClassName}>
          <NavigateNextOutlinedIcon />
        </Button>

        <Button type="outlined" className={buttonClassName}>
          <SkipNextOutlinedIcon />
        </Button>
      </Row>
    </Row>
  )
}

export default TablePagination
