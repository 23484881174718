import { FormControlLabel, Radio, RadioProps } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'

import styles from './Radio.module.scss'

const CustomRadio: FC<RadioProps & { label?: string }> = (props) => {
  const { disabled, label } = props

  const Element = (
    <Radio
      {...props}
      disabled={disabled}
      color="primary"
      classes={{ root: styles.radio, checked: styles.checked }}
    />
  )

  return label ? (
    <FormControlLabel
      control={Element}
      label={label}
      classes={{ label: clsx(styles.label, disabled && styles.disabled) }}
    />
  ) : (
    Element
  )
}

export default CustomRadio
