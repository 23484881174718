import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import {
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Select
} from '@/common/components/atoms'
import { AppointmentTypes } from '@/common/types/enums/transactionDetails'
import { statesOptions } from '@/common/utils/data'
import {
  accountsSelectOptions,
  carriersSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions
} from '@/common/utils/mappers'
import { DriverDetailsSchema } from '@/features/gate/utils'

const DriverDetailsPowerUnitFormSection: FC = () => {
  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof DriverDetailsSchema>>()

  const puCarrier = watch('operatingCarrier')
  const lpnState = watch('puLPNState')
  const puClass = watch('puClass')
  const fuelType = watch('puFuelType')
  const account = watch('account')
  const appointmentType = watch('appointmentType')
  const puVIN = watch('puVIN')

  const showUSDOT = appointmentType !== AppointmentTypes.Visitor

  return (
    <Col items="stretch" gap={20}>
      <Input
        {...register('puName')}
        required
        uppercase
        fullWidth
        label="ID"
        error={!!errors.puName}
        helperText={errors.puName?.message}
      />

      <Row gap={16}>
        {showUSDOT && (
          <Input
            {...register('usDOT')}
            required
            fullWidth
            digitsOnly
            type="number"
            label="US DOT #"
            error={!!errors.usDOT}
            helperText={errors.usDOT?.message}
          />
        )}

        <Select
          required
          label="Carrier"
          value={puCarrier}
          error={!!errors.operatingCarrier}
          helperText={errors.operatingCarrier?.message}
          options={carriersSelectOptions}
          onChange={(value) => setValue('operatingCarrier', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('puLPN')}
          required
          uppercase
          fullWidth
          label="LPN"
          error={!!errors.puLPN}
          helperText={errors.puLPN?.message}
        />

        <Select
          required
          label="LPN State/Region"
          value={lpnState}
          options={statesOptions}
          error={!!errors.puLPNState}
          helperText={errors.puLPNState?.message}
          onChange={(value) => setValue('puLPNState', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Select
          required
          value={puClass}
          label="Class"
          options={classListSelectOptions}
          error={!!errors.puClass}
          helperText={errors.puClass?.message}
          onChange={(value) => setValue('puClass', value as string)}
        />

        <Select
          required
          value={fuelType}
          label="Fuel Type"
          options={fuelTypeSelectOptions}
          error={!!errors.puFuelType}
          helperText={errors.puFuelType?.message}
          onChange={(value) => setValue('puFuelType', value as string)}
        />
      </Row>

      <Row gap={16}>
        <ReadOnlyFormValue title="VIN" value={puVIN} />

        <Select
          multiple
          label="Account"
          value={account}
          options={accountsSelectOptions}
          error={!!errors.account}
          helperText={errors.account?.message}
          onChange={(value) => setValue('account', value as string[])}
        />
      </Row>
    </Col>
  )
}

export default DriverDetailsPowerUnitFormSection
