import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined'
import { FC } from 'react'

import { EnterpriseRoles, SiteRoles } from '@/common/types/enums/global'

import ROUTES from '../../../../router/routes'

export interface INavigationItem {
  id: string
  title: string
  link?: string
  items?: this[]
  icon?: FC
}

export type INavigationItems<T extends keyof any> = {
  [key in T]: INavigationItem[]
}

export const items = [
  {
    id: 'gate',
    title: 'Gate',
    icon: LocalShippingOutlinedIcon,
    items: [
      {
        id: 'queue',
        title: 'Queue',
        link: ROUTES.GATE_QUEUE
      },
      {
        id: 'transactions',
        title: 'Transactions',
        link: ROUTES.GATE_TRANSACTIONS
      },
      {
        id: 'drivers',
        title: 'Drivers',
        link: ROUTES.GATE_DRIVERS
      }
    ]
  },
  {
    id: 'yard',
    title: 'Yard',
    icon: ContentPasteOutlinedIcon,
    items: [
      {
        id: 'cargo-asset',
        title: 'Cargo Asset',
        link: ROUTES.YARD_CARGO_ASSET
      }
    ]
  },
  {
    id: 'isr',
    title: 'ISR',
    link: ROUTES.ISR,
    icon: WbSunnyOutlinedIcon
  },
  {
    id: 'admin',
    title: 'Administration',
    icon: AdminPanelSettingsOutlinedIcon,
    items: [
      {
        id: 'mismatches',
        title: 'Mismatches',
        link: ROUTES.ADMIN_MISMATCHES
      },
      {
        id: 'video',
        title: 'Video',
        link: ROUTES.ADMIN_VIDEO
      }
    ]
  },
  {
    id: 'gate',
    title: 'Gate',
    icon: LocalShippingOutlinedIcon,
    link: ROUTES.GATE_TRANSACTIONS
  },
  {
    id: 'users',
    title: 'Users',
    link: ROUTES.USERS,
    icon: GroupOutlinedIcon
  }
]

export const sitePortalNavigation: INavigationItems<SiteRoles> = {
  [SiteRoles.Admin]: [items[0], items[1], items[3]],
  [SiteRoles.ISRManager]: [items[2]],
  [SiteRoles.Gate]: [items[0], items[1]],
  [SiteRoles.ViewOnly]: [items[4], items[1]]
}

export const enterprisePortalNavigation: INavigationItems<EnterpriseRoles> = {
  [EnterpriseRoles.Admin]: [items[5]]
}
