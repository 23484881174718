// Get first letter of the username to render in Avatar component for profile
const getFirstUsernameLetter = (name: string) => {
  if (!name) return 'U'

  const firstLetter = name.split(' ')[0][0]

  return firstLetter.toUpperCase()
}

export default getFirstUsernameLetter
