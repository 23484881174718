import dayjs from 'dayjs'

const prepareCargoAssetUrlFilters = (
  assetCarrierFilter: string | null,
  mismatch: string | null,
  dwell: string | null
) => {
  const values: any = {}

  if (assetCarrierFilter) {
    values.assetCarrier = [assetCarrierFilter]
  }

  if (mismatch) {
    values.mismatch = [mismatch]
  }

  if (dwell) {
    values.assetDwell = dayjs().startOf('day').add(Number(dwell), 'millisecond')
  }

  return Object.keys(values).length ? values : undefined
}

export default prepareCargoAssetUrlFilters
