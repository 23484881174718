import { EmissionType } from '@/common/types/enums/transactionDetails'
import { emissionNameMapper } from '@/common/utils/mappers'

const userFriendlyEmissionType = (row: any, id: string) => {
  if (row[id]) {
    const friendlyName = emissionNameMapper[row[id] as EmissionType]

    return friendlyName || '-'
  }

  return '-'
}

export default userFriendlyEmissionType
