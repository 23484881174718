import AddIcon from '@mui/icons-material/Add'
import React, { FC, useState } from 'react'

import {
  Button,
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { useModal } from '@/common/hooks'
import { TransactionDirectionTypes } from '@/common/types/enums/transactionDetails'
import {
  CheckInModal,
  CheckOutModal,
  DeleteQueueItemModal,
  EmptyQueue,
  QueueCard
} from '@/features/gate'
import { IGateQueueItem, IQueueModalType } from '@/features/gate/types'
import { Color } from '@/packages/palette'

import styles from './GateQueue.module.scss'

interface IProps {
  type: TransactionDirectionTypes
  items: IGateQueueItem[]
  onDelete: (type: TransactionDirectionTypes, id: string) => void
  onSwap: (type: TransactionDirectionTypes, id: string) => void
}

const GateQueue: FC<IProps> = (props) => {
  const { type, items, onDelete, onSwap } = props

  const [selectedItem, setSelectedItem] = useState<IGateQueueItem | undefined>()

  const { visible, openModal, closeModal } = useModal<IQueueModalType>()

  const title =
    type === TransactionDirectionTypes.CheckIn ? 'Check-In' : 'Check-Out'

  const openModalCustom = (
    item: IGateQueueItem | undefined,
    modalType: IQueueModalType
  ) => {
    setSelectedItem(item)
    openModal(modalType)
  }

  const closeModalCustom = () => {
    closeModal()
    setSelectedItem(undefined)
  }

  const onManualCheckIn = () => {
    openModalCustom(undefined, type)
  }

  const handleDelete = () => {
    if (!selectedItem) return

    onDelete(type, selectedItem.id)
    closeModal()
  }

  return (
    <Col items="stretch" className={styles.queueWrapper}>
      {visible === TransactionDirectionTypes.CheckIn && (
        <CheckInModal closeModal={closeModalCustom} item={selectedItem} />
      )}

      {visible === TransactionDirectionTypes.CheckOut && (
        <CheckOutModal closeModal={closeModalCustom} item={selectedItem} />
      )}

      {visible === 'delete' && (
        <DeleteQueueItemModal
          closeModal={closeModalCustom}
          onDelete={handleDelete}
        />
      )}

      <Row
        items="center"
        gap={20}
        justify="between"
        className={styles.queueHeader}
      >
        <Row items="center" gap={10}>
          <Text
            type={TextTypes.TEXT_MD}
            color={Color.gray700}
            weight={FontWeight.SEMIBOLD}
          >
            {title}
          </Text>

          {!!items.length && (
            <div className={styles.queueLength}>
              <Text
                type={TextTypes.TEXT_MD}
                weight={FontWeight.SEMIBOLD}
                color={Color.gray700}
              >
                {items.length}
              </Text>
            </div>
          )}
        </Row>

        <Button type="outlined" onClick={onManualCheckIn}>
          <AddIcon />
        </Button>
      </Row>

      {items.length ? (
        <Col items="stretch" gap={8} className={styles.queueItems}>
          {items.map((item) => (
            <QueueCard
              key={item.id}
              type={type}
              item={item}
              onSwap={onSwap}
              openModal={openModalCustom}
            />
          ))}
        </Col>
      ) : (
        <EmptyQueue type={type} />
      )}
    </Col>
  )
}

export default GateQueue
