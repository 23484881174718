import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { MainLayout } from '@/common/templates'
import { LogoutTemplate } from '@/features/auth'
import {
  GateDriversTemplate,
  GateQueueTemplate,
  GateTransactionTemplate
} from '@/features/gate'
import { ISRTemplate } from '@/features/isr'
import { ProfileTemplate } from '@/features/profile'
import { SuperAdminPortalTemplate } from '@/features/super-admin-portal'
import { TemporaryScreen } from '@/features/temporary'
import { UserManagementTemplate } from '@/features/user-management'
import { CargoAssetTemplate } from '@/features/yard'
import ROUTES from '@/router/routes'

import RouteDispatcher from './RouteDispatcher'

const RootRouter: FC = () => (
  <RouteDispatcher>
    <Routes>
      <Route path={ROUTES.LOGOUT} element={<LogoutTemplate />} />

      <Route element={<MainLayout />}>
        <Route path={ROUTES.HOME} element={<TemporaryScreen />} />
        <Route
          path={ROUTES.GATE_TRANSACTIONS}
          element={<GateTransactionTemplate />}
        />
        <Route path={ROUTES.GATE_QUEUE} element={<GateQueueTemplate />} />
        <Route path={ROUTES.GATE_DRIVERS} element={<GateDriversTemplate />} />
        <Route path={ROUTES.ISR} element={<ISRTemplate />} />
        <Route path={ROUTES.ADMIN_VIDEO} element={<TemporaryScreen />} />
        <Route path={ROUTES.ADMIN_MISMATCHES} element={<TemporaryScreen />} />
        <Route
          path={ROUTES.YARD_CARGO_ASSET}
          element={<CargoAssetTemplate />}
        />
        <Route path={ROUTES.USERS} element={<UserManagementTemplate />} />
        <Route path={ROUTES.PROFILE} element={<ProfileTemplate />} />
        <Route
          path={ROUTES.SUPER_ADMIN_PORTAL}
          element={<SuperAdminPortalTemplate />}
        />
      </Route>
    </Routes>
  </RouteDispatcher>
)

export default RootRouter
