import SearchIcon from '@mui/icons-material/Search'
import { ChangeEvent, FC } from 'react'

import { Input } from '@/common/components/atoms'

interface IProps {
  value: string | undefined
  placeholder?: string
  onUpdate: (value: string | undefined) => void
}

const SearchInput: FC<IProps> = (props) => {
  const { value, onUpdate, placeholder = 'Search' } = props

  const handleUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    onUpdate(e.target.value ? e.target.value : undefined)
  }

  return (
    <Input
      icon={<SearchIcon width={16} height={16} />}
      variant="outlined"
      value={value}
      onChange={handleUpdate}
      type="text"
      placeholder={placeholder}
    />
  )
}

export default SearchInput
