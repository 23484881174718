import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import { InputLabel } from '@mui/material'
import clsx from 'clsx'
import React, { FC } from 'react'

import { ReactComponent as BobtailTruck } from '@/assets/icons/bobtail_truck.svg'
import { ReactComponent as DropTruck } from '@/assets/icons/drop_truck.svg'
import {
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/common/components/atoms'
import inputStyles from '@/common/components/atoms/Input/Input.module.scss'
import { AppointmentTypes } from '@/common/types/enums/transactionDetails'
import { Color } from '@/packages/palette'

import styles from './AppointmentTypeSelector.module.scss'

interface IProps {
  value: AppointmentTypes | undefined
  onChange: (value: AppointmentTypes) => void
}

const AppointmentTypeSelector: FC<IProps> = (props) => {
  const { value, onChange } = props

  const labelClasses = {
    asterisk: inputStyles.asterisk,
    root: inputStyles.label,
    error: inputStyles.error
  }

  const items = [
    { id: AppointmentTypes.DROP, icon: <DropTruck /> },
    { id: AppointmentTypes.LIVE, icon: <LocalShippingOutlinedIcon /> },
    { id: AppointmentTypes.Bobtail, icon: <BobtailTruck /> },
    { id: AppointmentTypes.Visitor, icon: <AirportShuttleOutlinedIcon /> }
  ]

  return (
    <Col>
      <InputLabel
        required
        focused
        shrink
        variant="standard"
        classes={labelClasses}
      >
        Appointment Type
      </InputLabel>

      <div className={styles.items}>
        {items.map((item) => (
          <Row
            gap={4}
            key={item.id}
            items="center"
            onClick={() => onChange(item.id)}
            className={clsx(styles.item, item.id === value && styles.selected)}
          >
            {item.icon}

            <Text
              color={Color.gray700}
              type={TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
            >
              {item.id}
            </Text>
          </Row>
        ))}
      </div>
    </Col>
  )
}

export default AppointmentTypeSelector
