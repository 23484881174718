import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import {
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import {
  Col,
  PortalDropdownItem,
  Text,
  TextTypes
} from '@/common/components/atoms'
import { BREAKPOINTS } from '@/common/constants'
import { useUserContext } from '@/common/contexts/UserContext'
import { PortalTypes } from '@/common/types/enums/global'
import { Color } from '@/packages/palette'

import styles from './PortalsDropdownList.module.scss'

const PortalsDropdownList: FC = () => {
  const { availablePortals, updatePortal, selectedPortal, portalType } =
    useUserContext()

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<string>(selectedPortal)

  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.MD

  const withSitePortals = !!availablePortals.sitePortals.length
  const showBorderForEnterprise =
    value !== PortalTypes.Enterprise && withSitePortals

  const toggleOpen = () => {
    setOpen(!open)
  }

  const onItemClick = (newValue: string) => {
    if (isSmallView) {
      setValue(newValue)
      updatePortal(newValue)
      setOpen(false)
    }
  }

  const onChange = (e: SelectChangeEvent<string>) => {
    setValue(e.target.value)
    updatePortal(e.target.value)
  }

  const Enterprise = (
    <MenuItem
      tabIndex={0}
      value={PortalTypes.Enterprise}
      className={clsx(
        value === PortalTypes.Enterprise && styles.hidden,
        showBorderForEnterprise && !isSmallView && styles.borderBottom
      )}
      onClick={() => onItemClick(PortalTypes.Enterprise)}
    >
      <PortalDropdownItem
        type={PortalTypes.Enterprise}
        text="Enterprise Portal"
      />
    </MenuItem>
  )

  const SubHeader = (
    <ListSubheader className={styles.subHeader}>
      <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
        Site Portal
      </Text>
    </ListSubheader>
  )

  const SiteItems = availablePortals.sitePortals.map((sitePortal, index) => (
    <MenuItem
      tabIndex={0}
      key={sitePortal.id}
      value={sitePortal.id}
      className={clsx(value === sitePortal.id && styles.hidden)}
      onClick={() => onItemClick(sitePortal.id)}
    >
      <PortalDropdownItem
        index={index}
        type={PortalTypes.Site}
        text={sitePortal.name}
      />
    </MenuItem>
  ))

  return (
    <div className={styles.portals}>
      <Select
        value={value}
        fullWidth={isSmallView}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        IconComponent={KeyboardArrowDownOutlinedIcon}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Portal' }}
        className={clsx(styles.wrapper, open && styles.opened)}
        classes={{
          select: clsx(styles.select, open && styles.open),
          icon: styles.icon
        }}
        MenuProps={{
          open: isSmallView ? false : open,
          classes: {
            root: styles.roooo,
            paper: styles.dropdown,
            list: styles.list
          }
        }}
      >
        {Enterprise}
        {SubHeader}
        {SiteItems}
      </Select>

      {open && (
        <Col className={styles.mobileList}>
          {Enterprise}
          {SubHeader}
          {SiteItems}
        </Col>
      )}
    </div>
  )
}

export default PortalsDropdownList
