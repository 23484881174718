import dayjs from 'dayjs'
import * as yup from 'yup'

const DriverDetailsSchema = yup.object({
  date: yup.object().test('dayjs', 'Date is required', (value) => {
    if (!value) {
      return true
    }
    return dayjs.isDayjs(value)
  }),
  time: yup.object().test('dayjs', 'Date is required', (value) => {
    if (!value) {
      return true
    }
    return dayjs.isDayjs(value)
  }),
  appointmentType: yup.string().required('Appointment Type is required'),

  puName: yup.string().max(50, 'Max length is 50').required('ID is required'),
  puLPN: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  puLPNState: yup.string().required('State is required'),
  operatingCarrier: yup.string().required('Carrier is required'),

  usDOT: yup
    .number()
    .test(
      'len',
      'Min length is 3',
      (val) => !!val && val?.toString()?.length >= 3
    )
    .test(
      'len',
      'Max length is 20',
      (val) => !!val && val?.toString()?.length <= 20
    )
    .required('US DOT # is required')
    .typeError('US DOT # must be a number'),
  puVIN: yup.string(),
  puClass: yup.string().required('Class is required'),
  puFuelType: yup.string().required('Fuel Type is required'),
  account: yup.array(),

  driverName: yup
    .string()
    .max(50, 'Max length is 50')
    .required('Driver Name is required'),
  driverLicenseNum: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Driver License is required'),
  driverPhoneNum: yup.string().notRequired()
})

export default DriverDetailsSchema
