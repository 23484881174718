import { faker } from '@faker-js/faker'

import {
  accountsList,
  appointmentTypesList,
  cargoAssetTypesList,
  carriersList,
  loadStatusesList
} from '@/common/utils/mappers'
import { ICargoAssetItem } from '@/features/yard/types'

const generateMockData = (): ICargoAssetItem => {
  const now = new Date()
  const transactionTime = faker.date.between(
    new Date().setDate(now.getDate() - 2),
    now
  )

  return {
    id: faker.datatype.uuid(),
    lane: 1,
    returnVisit: true,
    img: 'https://cdn.britannica.com/17/126517-050-9CDCBDDF/semi-semitrailer-truck-tractor-highway.jpg',

    transactionTime,
    appointmentType: faker.helpers.arrayElement(appointmentTypesList),

    assetName: faker.random.alphaNumeric(9).toUpperCase(),
    chassisName: faker.datatype
      .number({ min: 100000000, max: 999999999 })
      .toString(),
    assetLPN: faker.vehicle.vrm(),
    assetLPNState: faker.address.stateAbbr(),
    assetCarrier: faker.helpers.arrayElement(carriersList),

    operatingCarrier: faker.helpers.arrayElement(carriersList),
    assetType: faker.helpers.arrayElement(cargoAssetTypesList),
    arrivingLoadStatus: faker.helpers.arrayElement(loadStatusesList),

    shipmentNumber: faker.random.alphaNumeric(9).toUpperCase(),
    account: [faker.helpers.arrayElement(accountsList)],

    sealNumber: faker.random.alphaNumeric(9).toUpperCase(),
    sealMatchPW: faker.datatype.boolean(),
    assetDwell: now.getTime() - transactionTime.getTime(),
    mismatch: faker.datatype.boolean(),

    driverName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    driverLicenseNum: faker.random.alphaNumeric(9).toUpperCase(),
    driverPhoneNum: faker.phone.number('(###) ###-####'),

    puName: faker.random.alphaNumeric(9).toUpperCase()
  }
}

const cargoAssetMockData: ICargoAssetItem[] = Array.from(
  { length: 10 },
  generateMockData
)

export default cargoAssetMockData
