import {
  CargoAssetTypes,
  PowerUnitTypes
} from '@/common/types/enums/transactionDetails'
import { isCargoAssetMinimized } from '@/features/gate/utils'

const isChassisIdVisible = (
  puType: PowerUnitTypes,
  assetType: CargoAssetTypes
) => {
  const isMinimized = isCargoAssetMinimized(puType)
  const isTrailer = assetType === CargoAssetTypes.Trailer

  return !isMinimized && !isTrailer
}

export default isChassisIdVisible
