import { EnterpriseRoles, SiteRoles } from '@/common/types/enums/global'
import { IUser } from '@/common/types/interfaces/global'
import { getDateDifference } from '@/common/utils/helpers'
import { IUsersSummary } from '@/features/user-management/types'

const generateUserManagementStats = (data: IUser[]) => {
  const values: IUsersSummary = data.reduce(
    (acc, item) => {
      if (item.enterpriseRoles.includes(EnterpriseRoles.Admin)) {
        acc.enterpriseAdmins += 1
      }

      if (item.siteRoles.includes(SiteRoles.Admin)) {
        acc.siteAdmins += 1
      }

      if (
        item.lastActivity &&
        getDateDifference(new Date(), item.lastActivity) >= 90
      ) {
        acc.inactive += 1
      }

      return acc
    },
    {
      total: data.length,
      siteAdmins: 0,
      enterpriseAdmins: 0,
      inactive: 0
    }
  )

  return [
    { id: 'total', title: 'Total', value: values.total },
    {
      id: 'enterpriseAdmins',
      title: 'Enterprise Admins',
      value: values.enterpriseAdmins,
      filterKey: 'role',
      filterValue: EnterpriseRoles.Admin
    },
    {
      id: 'siteAdmins',
      title: 'Site Admins',
      value: values.siteAdmins,
      filterKey: 'role',
      filterValue: SiteRoles.Admin
    },
    {
      id: 'inactive',
      title: '90 Days+ Inactivity',
      value: values.inactive,
      filterKey: 'inactivity',
      filterValue: 'true'
    }
  ]
}

export default generateUserManagementStats
