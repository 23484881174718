import { FC } from 'react'

import { Col, Text, TextTypes } from '@/common/components/atoms'
import { Color } from '@/packages/palette'

interface IProps {
  title: string
  value: string | number | undefined | null
}

const ReadOnlyFormValue: FC<IProps> = (props) => {
  const { title, value } = props

  return (
    <Col gap={!value ? 5 : 2} className="tw-w-full tw-self-stretch">
      <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
        {title}
      </Text>
      <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
        {value || '--'}
      </Text>
    </Col>
  )
}

export default ReadOnlyFormValue
