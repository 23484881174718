import {
  Accounts,
  AppointmentTypes,
  CargoAssetTypes,
  Carriers,
  Classes,
  EmissionType,
  EventTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  TransactionDirectionTypes
} from '@/common/types/enums/transactionDetails'

export const emissionTypeMapper: { [key in FuelTypes]: EmissionType } = {
  [FuelTypes.HFC]: EmissionType.NZE,
  [FuelTypes.CNG]: EmissionType.NZE,
  [FuelTypes.LNG]: EmissionType.NZE,
  [FuelTypes.Gas]: EmissionType.E,
  [FuelTypes.Diesel]: EmissionType.E,
  [FuelTypes.Electric]: EmissionType.ZE
}

export const emissionNameMapper: { [key in EmissionType]: string } = {
  [EmissionType.E]: 'Emission',
  [EmissionType.NZE]: 'Near Zero Emission',
  [EmissionType.ZE]: 'Zero Emission'
}

export const fuelTypesList: FuelTypes[] = Object.values(FuelTypes)
export const classesList: Classes[] = Object.values(Classes)
export const emissionsList: EmissionType[] = Object.values(EmissionType)
export const accountsList: Accounts[] = Object.values(Accounts)
export const carriersList: Carriers[] = Object.values(Carriers)

export const transactionDirectionsList: TransactionDirectionTypes[] =
  Object.values(TransactionDirectionTypes)
export const eventTypesList: EventTypes[] = Object.values(EventTypes)
export const loadStatusesList: LoadTypes[] = Object.values(LoadTypes)
export const appointmentTypesList: AppointmentTypes[] =
  Object.values(AppointmentTypes)
export const cargoAssetTypesList: CargoAssetTypes[] =
  Object.values(CargoAssetTypes)
export const powerUnitTypesList: PowerUnitTypes[] =
  Object.values(PowerUnitTypes)

export const classListSelectOptions = classesList.map((classItem) => ({
  id: classItem,
  label: classItem
}))

export const fuelTypeSelectOptions = fuelTypesList.map((fuelType) => ({
  id: fuelType,
  label: fuelType
}))

export const carriersSelectOptions = carriersList.map((carrier) => ({
  id: carrier,
  label: carrier
}))

export const accountsSelectOptions = accountsList.map((account) => ({
  id: account,
  label: account
}))

export const loadStatusSelectOptions = loadStatusesList.map((status) => ({
  id: status,
  label: status
}))

export const cargoAssetTypeSelectOptions = cargoAssetTypesList.map((asset) => ({
  id: asset,
  label: asset
}))

export const powerUnitTypeSelectOptions = powerUnitTypesList.map((type) => ({
  id: type,
  label: type
}))
