import { EnterpriseRoles, SiteRoles } from '@/common/types/enums/global'
import { TableFilterType } from '@/common/types/enums/table'
import { IFilterItem, IFilterListOption } from '@/common/types/interfaces/table'

const enterpriseRoles: IFilterListOption[] = [
  { id: EnterpriseRoles.Admin, title: 'Admin' }
]
const siteRoles: IFilterListOption[] = [
  { id: SiteRoles.Admin, title: 'Admin' },
  { id: SiteRoles.ISRManager, title: 'ISR Manager' },
  { id: SiteRoles.Gate, title: 'Gate' },
  { id: SiteRoles.ViewOnly, title: 'View Only' }
]
const sites = [
  {
    id: 'site1',
    title: 'Site 1'
  },
  {
    id: 'site2',
    title: 'Site 2'
  },
  {
    id: 'site3',
    title: 'Site 3'
  }
]

const userManagementFilters: IFilterItem[] = [
  {
    id: 'role',
    name: 'Role',
    type: TableFilterType.GroupedList,
    placeholder: 'Search Roles',
    options: [
      {
        id: 'enterpriseRoles',
        title: 'Enterprise',
        options: enterpriseRoles
      },
      {
        id: 'siteRoles',
        title: 'Site',
        options: siteRoles
      }
    ]
  },
  {
    id: 'lastActivity',
    name: 'Last Active',
    type: TableFilterType.DateRange,
    extraOptions: [
      {
        id: 'no',
        title: 'Never Logged In',
        isValid: (row) => !row.lastActivity
      }
    ]
  },
  {
    id: 'availableSites',
    name: 'Site',
    type: TableFilterType.List,
    placeholder: 'Search Sites',
    options: sites
  }
]

export default userManagementFilters
