import * as yup from 'yup'

const IsrVisitSchema = yup.object({
  puName: yup.string().max(20, 'Max length is 50').required('ID is required'),
  puLPN: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  puLPNState: yup.string().required('State is required'),
  usDOT: yup
    .number()
    .test(
      'len',
      'Min length is 3',
      (val) => !!val && val?.toString()?.length >= 3
    )
    .test(
      'len',
      'Max length is 20',
      (val) => !!val && val?.toString()?.length <= 20
    )
    .required('US Dot is required')
    .typeError('US Dot must be a number'),
  puVIN: yup.string().max(50, 'Max length is 50').required('VIN is required'),
  puClass: yup.string().required('PU Class is required'),
  puFuelType: yup.string().required('Fuel Types is required'),
  account: yup.array().required('Account is required'),

  driverName: yup
    .string()
    .max(50, 'Max length is 50')
    .required('Driver name is required'),
  driverLicenseNum: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Driver license is required'),

  checkInAssetName: yup
    .string()
    .max(50, 'Max length is 50')
    .required('ID is required'),
  checkInAssetLPN: yup
    .string()
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  checkInAssetLPNState: yup.string().required('LPN State is required'),
  checkInShipmentNumber: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Shipment # is required'),
  checkInSealNumber: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Seal # is required'),
  checkInSealMatchPW: yup.boolean().required('Matches field is required'),

  checkOutAssetName: yup
    .string()
    .max(50, 'Max length is 50')
    .required('ID is required'),
  checkOutAssetLPN: yup
    .string()
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  checkOutAssetLPNState: yup.string().required('LPN State is required'),
  checkOutShipmentNumber: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Shipment # is required'),
  checkOutSealNumber: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Seal # is required'),
  checkOutSealMatchPW: yup.boolean().required('Matches field is required')
})

export default IsrVisitSchema
