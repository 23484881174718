import {
  AppointmentTypes,
  EventTypes
} from '@/common/types/enums/transactionDetails'
import { IStatsItem } from '@/common/types/interfaces/ui'
import { IGateTransaction } from '@/features/gate/types'

const generateTransactionsStats = (rows: IGateTransaction[]): IStatsItem[] => {
  const detectionsLength = rows.filter(
    (item) => item.eventType === EventTypes.Detection
  ).length
  const mismatchLength = rows.filter((item) => item.mismatch).length

  return [
    { id: 'total', title: 'Total Transactions', value: rows.length },
    {
      id: 'detection',
      title: '% Detection',
      value: `${
        detectionsLength
          ? Math.round((detectionsLength / rows.length) * 1000) / 10
          : 0
      }%`
    },
    {
      id: 'mismatch',
      title: '% Mismatch',
      value: `${
        mismatchLength
          ? Math.round((mismatchLength / rows.length) * 1000) / 10
          : 0
      }%`
    },
    {
      id: 'drop',
      title: 'Total DROP',
      value: rows.filter(
        (item) => item.appointmentType === AppointmentTypes.DROP
      ).length,
      filterKey: 'appointmentType',
      filterValue: AppointmentTypes.DROP
    },
    {
      id: 'live',
      title: 'Total LIVE',
      value: rows.filter(
        (item) => item.appointmentType === AppointmentTypes.LIVE
      ).length,
      filterKey: 'appointmentType',
      filterValue: AppointmentTypes.LIVE
    },
    {
      id: 'bobtail',
      title: 'Total Bobtail',
      value: rows.filter(
        (item) => item.appointmentType === AppointmentTypes.Bobtail
      ).length,
      filterKey: 'appointmentType',
      filterValue: AppointmentTypes.Bobtail
    },
    {
      id: 'visitor',
      title: 'Total Visitor',
      value: rows.filter(
        (item) => item.appointmentType === AppointmentTypes.Visitor
      ).length,
      filterKey: 'appointmentType',
      filterValue: AppointmentTypes.Visitor
    }
  ]
}

export default generateTransactionsStats
