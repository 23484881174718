import clsx from 'clsx'
import { FC, PropsWithChildren, ReactNode } from 'react'

import { Col } from '@/common/components/atoms'

import styles from './TwoColumnModalWrapper.module.scss'

interface IProps {
  leftSide?: ReactNode
}

const TwoColumnModalWrapper: FC<IProps & PropsWithChildren> = ({
  children,
  leftSide
}) => (
  <div className={clsx(styles.wrapper, !leftSide && styles.hideSidebar)}>
    {!!leftSide && (
      <Col items="stretch" className={styles.leftSide}>
        {leftSide}
      </Col>
    )}

    <div className={styles.formContent}>{children}</div>
  </div>
)

export default TwoColumnModalWrapper
