import { EnterpriseRoles, SiteRoles } from '@/common/types/enums/global'

const rolesByPortalList = {
  enterprise: [{ id: EnterpriseRoles.Admin, label: 'Admin' }],
  site: [
    { id: SiteRoles.Admin, label: 'Admin' },
    { id: SiteRoles.ISRManager, label: 'ISR Manager' },
    { id: SiteRoles.Gate, label: 'Gate' },
    { id: SiteRoles.ViewOnly, label: 'View Only' }
  ]
}

export default rolesByPortalList
