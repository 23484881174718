import { EnterpriseRoles, SiteRoles } from '@/common/types/enums/global'
import {
  StringArrayMap,
  StringKeyedObject
} from '@/common/types/interfaces/common'
import { IUser } from '@/common/types/interfaces/global'

interface IPortalGrouped {
  enterprise: IUser[]
  site: IUser[]
}

const roleNameMapper: { [key in SiteRoles | EnterpriseRoles]: string } = {
  [SiteRoles.Admin]: 'Site Admin',
  [SiteRoles.Gate]: 'Gate',
  [SiteRoles.ViewOnly]: 'View Only',
  [SiteRoles.ISRManager]: 'Site ISR Manager',
  [EnterpriseRoles.Admin]: 'Enterprise Admin'
}

const userManagementGroupingHelper = (groupBy: string, rows: IUser[]) => {
  if (rows.length === 0) return []

  switch (groupBy) {
    case 'portal': {
      const groupedRows = rows.reduce(
        (groups: IPortalGrouped, item: IUser) => {
          if (item.enterpriseRoles.length) {
            groups.enterprise.push(item)
          }

          if (item.siteRoles.length) {
            groups.site.push(item)
          }

          return groups
        },
        { enterprise: [], site: [] }
      )

      return Object.entries(groupedRows).reduce((acc: any[], [key, value]) => {
        if (value.length) {
          acc.push({
            id: key,
            title: key.toUpperCase(),
            rows: value
          })
        }

        return acc
      }, [])
    }

    case 'role': {
      const groupedRows = rows.reduce((groups: StringArrayMap, item) => {
        const tempGroups = groups

        item.siteRoles.forEach((role) => {
          if (Array.isArray(groups[role])) {
            tempGroups[role].push(item)
          } else {
            tempGroups[role] = [item]
          }
        })

        item.enterpriseRoles.forEach((role) => {
          if (Array.isArray(groups[role])) {
            tempGroups[role].push(item)
          } else {
            tempGroups[role] = [item]
          }
        })

        return tempGroups
      }, {})

      return Object.entries(groupedRows).map(([key, value]) => ({
        id: key,
        title: roleNameMapper[key as EnterpriseRoles],
        rows: value
      }))
    }

    case 'site': {
      const names: StringKeyedObject = {}

      const groupedRows = rows.reduce((groups: StringArrayMap, item) => {
        const tempGroups = groups

        groups?.availableSites?.forEach?.((site) => {
          if (Array.isArray(groups[site.id])) {
            tempGroups[site.id].push(item)
          } else {
            tempGroups[site.id] = [item]
            names[site.id] = site.name
          }
        })

        return tempGroups
      }, {})

      return Object.entries(groupedRows).map(([key, value]) => ({
        id: key,
        title: names[key],
        rows: value
      }))
    }

    default: {
      return rows
    }
  }
}

export default userManagementGroupingHelper
