import { EmissionType } from '@/common/types/enums/transactionDetails'
import { StringArrayMap } from '@/common/types/interfaces/common'
import { emissionNameMapper } from '@/common/utils/mappers'
import { IISRItem } from '@/features/isr/types'

const isrGroupingHelper = (groupBy: string, rows: IISRItem[]) => {
  if (rows.length === 0) return []

  switch (groupBy) {
    case 'emissionType': {
      const groupedRows = rows.reduce((groups: StringArrayMap, item) => {
        const { emissionType } = item
        const tempGroups = groups

        if (Array.isArray(groups[emissionType])) {
          tempGroups[emissionType].push(item)
        } else {
          tempGroups[emissionType] = [item]
        }

        return tempGroups
      }, {})

      return Object.entries(groupedRows).map(([key, value]) => ({
        id: key,
        title: emissionNameMapper[key as EmissionType],
        rows: value
      }))
    }

    default: {
      return rows
    }
  }
}

export default isrGroupingHelper
